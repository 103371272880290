import { AppDropdown } from "./Inputs";
import { OutlineButton, OutlineIconButton } from "./Buttons";
import { capitalizeFirst } from "../utils/helpers";
import { ArrowDown } from "./Icons";

type FilterDropdownProps = {
  options: string[];
  applyFilter: (selectedOptions: string[]) => void;
  searchLabel?: string;
  position?: string;
  disabled?: boolean;
  showSelectAllBtn?: boolean;
};

export const FilterDropdown = ({
  options,
  applyFilter,
  searchLabel,
  position,
  disabled = false,
  showSelectAllBtn = true,
}: FilterDropdownProps) => {
  const DropdownButton = () => (
    <OutlineIconButton className="!h-10 !w-fit !max-w-none border-secondary-gray-350 rounded-md text-secondary-gray-500 !p-[20px] mb-[20px]">
      {searchLabel ? capitalizeFirst(searchLabel) : "Filter"}
      <ArrowDown />
    </OutlineIconButton>
  );

  const clearFilters = () => {
    applyFilter([]);
  };

  return (
    <AppDropdown
      disabled={disabled}
      defaultSelectAll={false}
      DropdownButton={DropdownButton}
      searchLabel={`Search a ${searchLabel?.toLowerCase()}...` || "Search..."}
      options={options}
      onSelectCallback={function (properties: string[]): void {}}
      actions={{
        primary: { title: "Apply", action: applyFilter },
        secondary: { title: "Clear", action: clearFilters },
      }}
      position={position ? position : "left-0"}
      className="!w-[300px]"
      showSelectAllBtn={showSelectAllBtn}
    />
  );
};

export const KpisDropdown = ({ options, applyFilter }: FilterDropdownProps) => {
  const DropdownButton = () => (
    <OutlineButton className="!h-10 !w-10">Customize</OutlineButton>
  );

  const clearFilters = () => {
    applyFilter([]);
  };

  return (
    <AppDropdown
      DropdownButton={DropdownButton}
      options={options}
      onSelectCallback={function (properties: string[]): void {}}
      actions={{
        primary: { title: "Apply", action: applyFilter },
        secondary: { title: "Clear", action: clearFilters },
      }}
      position="left-[-180px]"
    />
  );
};
