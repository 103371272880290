import { LogoBlack } from "./Icons.tsx";

const AppLoader = () => {
  return (
    <div className="flex gap-2 items-center justify-center w-full h-screen motion-safe:animate-[pulse_2s_infinite]">
      <LogoBlack className="w-[800px] h-[100px]" />
    </div>
  );
};

export default AppLoader;
