import { createSlice } from '@reduxjs/toolkit';
import { PropertiesDTO, PropertiesOverallDTO } from '../../models/models';

interface PropertiesState {
    properties: PropertiesDTO,
    propertiesOverall: PropertiesOverallDTO
}

const initialState: PropertiesState = {
    properties: [],
    propertiesOverall: {
        lastUpdatedAt: new Date(),
        noi: {
            value: null,
            trend: null
        },
        occupancy: {
            value: null,
            trend: null
        },
        delinquency: {
            actual: null, grossPotentialRent: null, previousActual: null, previousGrossPotentialRent:  null
        },
        budget: {
            planned: null,
            actual: null
        },
        turnovers: null,
        expectedRenovations: {
            count: null,
            totalCost: null
        }
    }
}

const PropertiesSlice = createSlice({name: 'properties', initialState, reducers: {
    setProperties(state, {payload: properties}) {
        return {...state, properties};
    },
    setPropertiesOverall(state, {payload: propertiesOverall}) {
        return {...state, propertiesOverall};
    },
}});

export const {setProperties, setPropertiesOverall} = PropertiesSlice.actions;
export default PropertiesSlice.reducer;