import { useEffect, useState } from "react";
import { KpisDropdown } from "./FilterDropdown";
import { Select, Option } from "@material-tailwind/react";
import { updateGraphParam } from "../api/api";
import { useAppDispatch } from "../app/hooks";
import {
  BreakDownOptions,
  GranularityType,
  TrendGraphDTO,
} from "../models/models";
import { updateTrend } from "../features/trends/trends-slice";
import { AppDropdown } from "./Inputs";

export const CustomizeProperties = () => {
  const [kpis, setKpis] = useState({
    "Expired Leases": { isSelected: false },
    Collection: { isSelected: false },
    Budget: { isSelected: false },
    Noi: { isSelected: false },
    Occupancy: { isSelected: false },
    Delinquency: { isSelected: false },
    "Expected Renovations": { isSelected: false },
    Turnovers: { isSelected: false },
  });

  return (
    <>
      <KpisDropdown
        options={Object.keys(kpis)}
        applyFilter={(selectedOptions) => {
          const options = { ...kpis };
          selectedOptions.forEach((o) => (options[o].isSelected = true));
          setKpis(options);
        }}
      />
    </>
  );
};

export const CustomizeBreakdownPeriod = ({
  setIsLoading,
  graphId,
  granularity,
}: {
  setIsLoading?: (isLoading: boolean) => void;
  graphId: string;
  granularity: GranularityType;
}) => {
  const dispatch = useAppDispatch();

  const [breakdownPeriod, setBreakdownPeriod] = useState<string>(
    BreakDownOptions[granularity]
  );
  const [error, setError] = useState<string>("");

  // Sync state with granularity changes
  useEffect(() => {
    setBreakdownPeriod(BreakDownOptions[granularity]);
  }, [granularity]);

  const handleBreakdownPeriodChange = async (
    graphId: string,
    selectedGranularity: GranularityType
  ) => {
    setIsLoading?.(true);

    try {
      const updatedGraph = await updateGraphParam({
        id: graphId,
        granularity: selectedGranularity,
      });
      dispatch(updateTrend(updatedGraph));
    } catch (e) {
      console.error(`Failed to update graph properties:`, e);
      setError(
        "Couldn't update granularity. Not enough data points to create the desired graph."
      );
      window.setTimeout(() => {
        setError("");
      }, 6000);
    } finally {
      setIsLoading?.(false);
    }
  };

  return breakdownPeriod ? (
    <>
      <Select
        className="bg-secondary-gray-200 border-none !border-t-transparent align-middle absolute max-w-[140px] top-0 right-0 h-[30px]"
        labelProps={{ className: `before:!hidden after:!hidden` }}
        value={breakdownPeriod}
      >
        {Object.keys(BreakDownOptions).map((o) => (
          <Option
            className="list-none"
            key={o}
            id={o || "NONE"}
            onClick={() => handleBreakdownPeriodChange(graphId, o)}
          >
            {BreakDownOptions[o as GranularityType] || "No Breakdown"}
          </Option>
        ))}
      </Select>
      {error?.length ? (
        <div className="bg-red-50 rounded-md py-2 px-3 text-red-300 animate-fadeIn z-20 text-[11px] font-semibold max-w-[450px] w-[450px] absolute right-[-50px] top-[330px]">
          {error}
        </div>
      ) : (
        ""
      )}
    </>
  ) : null;
};

export const CustomizeSelectedProperties = ({
  setIsLoading,
  graphId,
  properties,
  allProperties,
  updateGraph,
}: {
  setIsLoading?: (isLoading: boolean) => void;
  graphId: string;
  properties: string[];
  allProperties: string[];
  updateGraph?: (newValue: TrendGraphDTO) => void;
}) => {
  const dispatch = useAppDispatch();
  let date = new Date();
  date.setDate(1); //the first day of current month

  const [updatedProperties, setUpdatedProperties] = useState<{
    [p: string]: boolean;
  }>({});

  const getPropertiesMap = (newProperties: string[]) => {
    const p = allProperties.reduce((acc, property) => {
      acc[property] = newProperties.includes(property);
      return acc;
    }, {} as { [p: string]: boolean });
    return p;
  };
  useEffect(() => {
    if (!Object.keys(updatedProperties)?.length) {
      const initialProperties = getPropertiesMap(properties);
      setUpdatedProperties(initialProperties);
    }
  }, []);

  const handlePropertiesChange = async (newProperties: string[]) => {
    setIsLoading && setIsLoading(true);
    setUpdatedProperties(getPropertiesMap(newProperties));
    try {
      const newGraph = await updateGraphParam({
        id: graphId,
        properties: newProperties,
      });
      if (newGraph) {
        dispatch(updateTrend(newGraph));
        updateGraph && updateGraph(newGraph);
      }
    } catch (e) {
      console.error(`Faild to get Properties Overall: ${e}`);
      // setIsLoading && setIsLoading(false);
    }
    // setIsLoading && setIsLoading(false);
  };

  return (
    <>
      <AppDropdown
        preSelect={updatedProperties}
        label={"Properties"}
        searchLabel="property"
        options={allProperties}
        defaultSelectAll={false}
        onSelectCallback={(selected) => {
          handlePropertiesChange(selected);
        }}
        buttonClassName=" !text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm rounded-[7px] border-blue-gray-200 border-t-transparent !bg-secondary-gray-200 border-none align-middle max-w-[140px] w-[140px] !h-[30px] !py-[5px]"
      />
    </>
  );
};
