import { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  ChatMessageDTO,
  LoriMessageWithAttachment,
  ROLE,
} from "../models/models";
import { ChatLogoSymbolIcon, SourcesIcon } from "./Icons";
import Typed from "typed.js";
import { FilePreview } from "./FileUploader";
import { getFormattedDateAndTime, openFileInNewTab } from "../utils/helpers";
import { parseDrilldownText } from "../utils/drilldownParser";
import { FilePreviewBullet } from "./FilePreview";

export const handleMessageDTO = (
  content: string | JSX.Element | LoriMessageWithAttachment
) => {
  // Check if content is LoriMessageWithAttachment
  const isLoriMessageArray = !!content?.type;

  if (isLoriMessageArray) {
    // Handle as LoriMessageWithAttachment[]
    return content.type === "csv_file" ? (
      <>
        <div className="flex gap-2 text-white font-normal text-sm py-3">
          <SourcesIcon /> Sources
        </div>
        <FilePreviewBullet
          type={"csv"}
          onClick={() => openFileInNewTab(content.content)}
          title={content.filename}
        />
      </>
    ) : content.type === "text" ? (
      ReactHtmlParser(parseDrilldownText([content.content]))
    ) : (
      content.content
    );
  }

  // Handle other types
  if (typeof content === "string") {
    return ReactHtmlParser(content);
  } else {
    // Handle JSX.Element
    return content;
  }
};

export const containsTextThatShouldBeTyped = (
  content: string | JSX.Element | LoriMessageWithAttachment
) => typeof content === "string" || (content?.type && content.type === "text");

export const ChatMessage = ({
  role,
  content,
  time,
  _key,
  typeMessage,
  typingRef,
  isLast,
  isFile,
}: ChatMessageDTO) => {
  const el = useRef(null);
  const [shouldTypeMessage, setShouldTypeMessage] = useState(
    role === ROLE.ASSISTANT &&
      containsTextThatShouldBeTyped(content) &&
      typingRef !== "undefined" &&
      typeMessage !== false
  );

  useEffect(() => {
    if (!isLast) {
      setShouldTypeMessage(false);
    }
  }, [isLast]);

  useEffect(() => {
    if (!containsTextThatShouldBeTyped(content) || !shouldTypeMessage) return;

    if (typingRef.current) {
      typingRef.current.destroy();
    }

    const stopAnimation = () => {
      setShouldTypeMessage(false);
      if (!typingRef?.current) return;
      typingRef.current.stop();
      typingRef.current.destroy();
      typingRef.current = null;
    };

    const options = {
      strings: [content?.content ?? content],
      typeSpeed: 8,
      contentType: "html",
      onComplete: () => {
        stopAnimation();
      },
    };

    typingRef.current = new Typed(el.current, options);

    // Handle tab visibility changes
    const handleVisibilityChange = () => {
      stopAnimation();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  return (
    <>
      <div
        className={`flex items-center gap-3 text-white/80 ${
          role === ROLE.USER ? "flex-row-reverse w-full pr-5" : ""
        }`}
      >
        {role !== ROLE.USER && (
          <div
            className={`flex justify-center items-center rounded-full w-[64px] h-[30px]`}
          >
            <ChatLogoSymbolIcon className="w-full h-full" />
          </div>
        )}
        <div>{role === ROLE.USER ? "You" : ""}</div>
      </div>
      <div
        key={_key}
        className={`w-auto p-2 flex flex-col ${
          typeMessage ? "animate-fadeIn" : ""
        } ${
          role === ROLE.USER
            ? "w-11/12 self-end bg-gradient-to-r from-[#006cff] to-[#006cff]/50"
            : "bg-black/40"
        } text-white m-2 mb-6 rounded-xl
            `}
      >
        <div
          data-testid="lori-response"
          className="self-start text-left font-extralight text-sm p-2 w-auto break-word overflow-x-hidden"
          style={{ overflowWrap: "anywhere" }}
        >
          <span className={shouldTypeMessage ? "" : "hidden"} ref={el} />
          <span
            className={shouldTypeMessage ? "hidden" : ""}
            data-testid={`${role === ROLE.USER ? "user" : "lori"}-message`}
          >
            {isFile ? (
              <FilePreview fileToUpload={content} />
            ) : (
              handleMessageDTO(content)
            )}
          </span>
        </div>
        <div className="self-end text-white/40 text-xs">
          {getFormattedDateAndTime(time ?? new Date())}
        </div>
      </div>
    </>
  );
};
