import Chart from "react-apexcharts";
import { TrendGraphDTO } from "../models/models";
import { memo, useCallback, useState } from "react";
import { CloseIcon, LoadingSymbolIcon, TrackIcon } from "./Icons";
import { CheckIcon } from "@heroicons/react/24/outline";
import { deleteGraph, generateTrendGragh } from "../api/api";
import {
  addTrend,
  addTrendOnProccesing,
  deleteTrend,
  removeTrendOnProccesing,
} from "../features/trends/trends-slice";
import { useAppDispatch } from "../app/hooks";
import { Drilldown } from "./Drilldown";
import { CustomizeBreakdownPeriod } from "./CustomizeProperties";
import { LoriLoader } from "./LoriLoader";
import { Frame } from "./Frame";
import { sortGraphData } from "../utils/helpers";
import { SmallText } from "../styles/Typography";

type GraphDTO = {
  dataPoints: TrendGraphDTO;
  hideTitle?: boolean;
};

export const Graph = memo(({ dataPoints, hideTitle }: GraphDTO) => {
  const [loadingGraph, setLoadingGraph] = useState(false);

  const setIsLoading = useCallback((isLoading: boolean) => {
    setLoadingGraph(isLoading);
  }, []);

  try {
    let options = JSON.parse(JSON.stringify(dataPoints.options));
    let series = dataPoints?.series;

    if (options.chart.type === "pie" || options.chart.type === "donut") {
      series = sortGraphData(dataPoints?.series);
      options.labels = sortGraphData(dataPoints?.series);
    }

    if (options.chart.type === "line") {
      options.chart.dropShadow = {
        enabled: true,
        color: "#000",
        top: 16,
        left: 7,
        blur: 8,
        opacity: 0.2,
      };
    }
    options.chart.redrawOnParentResize = true;
    options.chart.stacked = true;
    options.chart.toolbar = {
      show: false,
    };
    options.chart.stroke = {
      curve: "smooth",
      width: 3,
    };
    options.chart.padding = {
      top: 20,
    };
    options.chart.events = {
      mounted: (chart) => {
        chart.windowResizeHandler();
      },
    };
    options.grid = {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    };
    options.legend = {
      position:
        options.chart.type === "pie" || options.chart.type === "donut"
          ? "right"
          : "bottom",
      horizontalAlign: "left",
      floating: false,
      offsetX: 0,
      offsetY: 10,
      height: "8px",
      fontSize: "10px",
      markers: {
        width: "4px",
        height: "4px",
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
    };
    options.title.text = "";
    if (options.yaxis) {
      if (options.yaxis.labels) {
        options.yaxis.labels.formatter = function (value: any) {
          return value.toLocaleString();
        };
      } else {
        options.yaxis.labels = {
          formatter: function (value: any) {
            return value.toLocaleString();
          },
        };
      }
    }

    return (
      <div className="h-full w-full">
        <div
          className={`absolute flex justify-center items-center top-0 left-0 ${
            loadingGraph ? "" : "hidden"
          } w-full h-full`}
        >
          <LoriLoader />
        </div>

        <div className={`app w-full h-full ${loadingGraph ? "hidden" : ""}`}>
          <Drilldown
            id={dataPoints.id}
            context={{ dashboard: dataPoints }}
            drilldownExportTitle={
              dataPoints?.options?.title?.text.replace(" ", "_") || "GRAPH"
            }
          />
          {dataPoints && !!dataPoints.track ? (
            <LoadingSymbolIcon className="absolute ml-[-18px] mt-[3px] motion-safe:animate-[spin_5s_infinite]" />
          ) : (
            ""
          )}
          <div className="flex flex-col animate-fadeIn w-full h-full">
            <div className="absolute top-4 right-16">
              {CustomizeBreakdownPeriod({
                graphId: dataPoints.id,
                granularity: dataPoints.granularity,
                setIsLoading,
              })}
            </div>
            {hideTitle ? (
              ""
            ) : (
              <>
                <SmallText className="font-extrabold !mt-0 !mb-0">
                  {dataPoints.options.title.text}
                </SmallText>
                <SmallText className=" !mt-0 !mb-0">
                  {dataPoints.properties?.length
                    ? dataPoints.properties?.length === 1
                      ? "For " + dataPoints.properties[0]
                      : "For selected properties"
                    : "Across your portfolio"}
                </SmallText>
              </>
            )}
            <div className="relative w-full h-full">
              <Chart
                id={dataPoints.id}
                options={options}
                series={series}
                type={options.chart.type}
                height="250px"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } catch (e) {
    return <></>;
  }
});

export const Graphs = ({ trends }: { trends: TrendGraphDTO[] }) => {
  const dispatch = useAppDispatch();
  const [wait, setWait] = useState(false);
  return trends?.map((t) => (
    <Frame
      key={t.id}
      className={`overflow-hidden resize w-[478px] h-[400px] min-h-[400px] min-w-[478px] max-w-[60vw] max-h-[60vh] pr-3 pl-12 pb-6 mb-0 pt-12 my-2 ${
        wait ? "bg-black/5 pointer-events-none animate-pulse" : ""
      }`}
    >
      <CloseIcon
        className="absolute top-4 left-4"
        onClose={() => {
          setWait(true);
          dispatch(deleteTrend(t.id));
          deleteGraph(t.id);
          setWait(false);
        }}
      />
      <Graph dataPoints={t} />
    </Frame>
  ));
};

export const LoriResponseGraph = ({ dataPoints }: GraphDTO) => {
  const [trendTracked, setTrendTracked] = useState(false);
  const dispatch = useAppDispatch();

  const trackTrend = async () => {
    dispatch(addTrendOnProccesing());
    setTrendTracked(true);
    try {
      const graphJson = await generateTrendGragh(dataPoints);
      dispatch(addTrend(graphJson));
    } catch (e) {
      dispatch(removeTrendOnProccesing());
    }
  };

  const series = dataPoints?.series;
  let options = dataPoints?.options;

  options = {
    ...options,
    ...{
      xaxis: {
        // type: "datetime",
        axisBorder: {
          show: true,
          color: "#ffffff",
        },
        labels: {
          show: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          style: {
            colors: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
            fontSize: "9px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 100,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        tickAmount: 6,
        axisBorder: {
          show: true,
          color: "#ffffff",
        },
        labels: {
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          maxHeight: 20,
          style: {
            colors: ["#ffffff"],
            fontSize: "9px",
            fontWeight: 100,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      chart: {
        id: dataPoints.id,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      colors: ["#006AF9", "#8ABAFC"],
      stroke: {
        curve: "smooth",
        width: 3,
      },
      dataLabels: {
        style: {
          colors: ["#ffffff"],
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        borderColor: "#ffffff",
        strokeDashArray: 2,
        padding: {
          top: 0,
          bottom: 30,
          left: 13, // or whatever value that works
          right: 2, // or whatever value that works
        },
      },
      legend: {
        offsetX: -88,
        fontSize: "11px",
        markers: {
          width: 7,
          height: 7,
        },
        labels: {
          colors: ["#ffffff", "#ffffff", "#ffffff"],
        },
      },
      title: {
        text: dataPoints.title || "",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#ffffff",
        },
      },
    },
  };

  return (
    <>
      <div className="app p-4 pb-0 pl-1 mb-4 bg-black rounded-lg">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              type="line"
              options={options}
              series={series}
              width="260"
              height="220"
            />
          </div>
        </div>
      </div>
      {trendTracked ? (
        <div className="w-full flex justify-end gap-2">
          <CheckIcon width={14} /> Trend tracked succesfully!
        </div>
      ) : (
        <div
          className="w-full flex justify-end cursor-pointer gap-1"
          onClick={trackTrend}
        >
          <TrackIcon /> Track this trend
        </div>
      )}
    </>
  );
};
