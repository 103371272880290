import { FileType } from "../models/models";
import { FileIcon, PdfFileIcon, XslFileIcon } from "./Icons";

export const FilePreviewBullet = ({
  type,
  onClick,
  title,
}: {
  type: FileType;
  onClick: () => void;
  title?: string;
}) => (
  <div className="relative mb-2">
    <div
      className="relative flex w-fit items-center gap-1 cursor-pointer bg-black/50 text-white border border-secondary-gray-350 font-normal text-xs rounded-md p-6 pl-2 no-underline h-10"
      onClick={() => onClick()}
      key={title}
    >
      {type === "pdf" ? (
        <PdfFileIcon className="h-6" />
      ) : type === "xsl" || type === "xlsx" || type === "csv" ? (
        <XslFileIcon className="h-6" />
      ) : (
        <FileIcon className="h-6" />
      )}
      <span>{title}</span>
    </div>
  </div>
);
