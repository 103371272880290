import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { LoriResponseDTO, MessageDTO, ROLE } from "../models/models";
import {
  askLori,
  deleteChat,
  getChat,
  getChatsHistory,
  renameChat,
  uploadWithLori,
} from "../api/api";
import {
  BackIcon,
  ChatLogoSymbolIcon,
  CloseIcon,
  CollapseIcon,
  DeleteIcon,
  DrilldownIcon,
  EditIcon,
  ExpandIcon,
  SendIcon,
  ToggleChatIcon,
} from "./Icons";
import StarsBg from "../assets/Stars.png";
import Loader from "../assets/loader_white.gif";
import { ChatButton } from "./ ChatButton";
import Typed from "typed.js";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { ChatMessage } from "./ChatMessage";
import {
  addMessage,
  closeChat,
  openChat,
  setChatHistory,
  setConversationID,
  setMessages,
} from "../features/messages/messages-slice";
import { FilePreview, FileUploader } from "./FileUploader";
import { ListOfChats } from "./ListOfChats";
import { CheckIcon } from "@heroicons/react/24/outline";

export const Lori = forwardRef((_props, ref) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const messagesRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [disableLori, setDisableLori] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isLoadingChatsList, setIsLoadingChatsList] = useState(false);
  const [messagePending, setMessagePending] = useState(true);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [chatMode, setChatMode] = useState<"chats" | "newChat" | "oldChat">(
    "chats"
  );
  const [currentConversationIDTitle, setCurrentConversationIDTitle] = useState<
    string | undefined
  >();
  const [openPopup, setOpenPopup] = useState(false);
  const [newName, setNewName] = useState<string>();
  const [renameMode, setRenameMode] = useState(false);
  const typingRef = useRef(null);
  const [typed, setTyped] = useState<Typed | null>(null);

  const isOpenChat = useAppSelector((state) => state.messages.isChatOpen);
  const chatsHistory = useAppSelector((state) => state.messages.chatsHistory);
  const conversationID = useAppSelector(
    (state) => state.messages.conversationID
  );
  const messages = useAppSelector((state) => state.messages.messages);
  const dispatch = useAppDispatch();
  const [oldM, setOldM] = useState(messages);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    setChatMode("newChat");
    if (isOpenChat && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpenChat]);

  useEffect(() => {
    setOldM(messages);
    const element = messagesRef.current;
    if (element) {
      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
      }, 0);
    }
  }, [messages]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (conversationID && conversationID > 0) {
          setDisableLori(true);
          // setIsLoadingChat(true);
          const chat = await getChat({ conversationID });
          setCurrentConversationIDTitle(chat.title);
          setOldM(chat.messages);
          dispatch(
            setMessages(
              chat.messages.map((m) => ({
                ...m,
                role: m.role,
                content: m.content,
                typeMessage: false,
              }))
            )
          );
        } else {
          setOldM([]);
          dispatch(setMessages([]));
          setOldM([]);
          setCurrentConversationIDTitle(undefined);
        }
      } catch (e) {
      } finally {
        setDisableLori(false);
        setIsLoadingChat(false);
      }
    };

    getData();
  }, [conversationID]);

  const handleSelectChatFromHistory = (cid: number) => {
    dispatch(setConversationID(cid));
    setChatMode("newChat");
  };

  const handleDeleteChat = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    chatID: number
  ) => {
    setIsLoadingChatsList(true);
    try {
      e.stopPropagation();
      setOpenPopup(false);
      showChatsList();
      await deleteChat({
        conversationID: chatID,
      });
      dispatch(
        setChatHistory([
          ...chatsHistory.filter((h) => h.conversationID !== chatID),
        ])
      );
    } catch (e) {
    } finally {
      setIsLoadingChatsList(false);
    }
  };

  const refetchChats = async () => {
    try {
      setIsLoadingChatsList(true);
      let history = await getChatsHistory();
      dispatch(setChatHistory(history));
    } catch (error) {
      console.error("Failed to fetch chats:", error);
    } finally {
      setIsLoadingChatsList(false);
    }
  };

  const ChatsList = useMemo(() => {
    return (
      <div
        className={`w-full h-full ${
          isLoadingChatsList
            ? "opacity-40 pointer-events-none animate-pulse"
            : ""
        }
      `}
      >
        <ListOfChats
          chatsHistory={chatsHistory}
          setCurrentConversationID={handleSelectChatFromHistory}
          setIsLoading={setIsLoadingChat}
          onDelete={handleDeleteChat}
        />
      </div>
    );
  }, [chatsHistory, conversationID, isLoadingChatsList]);

  const showChatsList = () => {
    setChatMode("chats");
    dispatch(setConversationID(0));
    ref;
  };

  const handleLorisResponse = (response: LoriResponseDTO) => {
    const messagesToSend = response?.messages?.map((m) => ({
      role: ROLE.ASSISTANT,
      content: m,
      typeMessage: true,
    }));

    messagesToSend?.length && sendNewMessages(messagesToSend);
    inputRef?.current?.focus();
  };

  const sendLorisResponse = (response: LoriResponseDTO) => {
    if (!conversationID && !conversationID) {
      dispatch(setConversationID(response.conversationID));
    }
    setLoadingResponse(false);
    handleLorisResponse(response);
  };

  const sendNewMessages = (messagesToSend: MessageDTO[]) => {
    messagesToSend.forEach(async (m) => {
      const role = m.role;
      const date = new Date();

      typingRef.current?.stop();
      dispatch(
        addMessage({
          role,
          content: m.content ?? m,
          time: date,
          typeMessage: m.typeMessage ?? true,
          isFile: m.isFile,
        })
      );
      setInputValue("");
      inputRef.current?.blur();
      const element = messagesRef.current;
      if (element) {
        setTimeout(() => {
          element.scrollTop = element.scrollHeight;
        }, 0);
      }
    });
  };

  const sendRequest = async (message: string) => {
    if (!message.replace(/\s/g, "").length && !fileToUpload) {
      //prevent empty message
      return;
    }

    setIsLoadingChatsList(true);

    if (fileToUpload) {
      sendNewMessages([
        {
          role: ROLE.USER,
          content: fileToUpload,
          typeMessage: false,
          isFile: true,
          conversationID,
        } as unknown as MessageDTO,
      ]);
      setFileToUpload(null);
      const response = await uploadWithLori(conversationID, fileToUpload);
      sendLorisResponse(response);
      return;
    }
    if (!message?.length) return;
    dispatch(openChat());
    sendNewMessages([
      {
        role: ROLE.USER,
        content: message,
        typeMessage: false,
        conversationID,
      } as unknown as MessageDTO,
    ]);
    setLoadingResponse(true);
    const element = messagesRef.current;
    if (element) {
      setTimeout(() => {
        element.scrollTop = element.scrollHeight;
      }, 0);
    }
    const response = await askLori(conversationID, message);
    sendLorisResponse(response);
    dispatch(setConversationID(response.conversationID));
    refetchChats();
  };

  useImperativeHandle(ref, () => ({
    askLori: sendRequest,
  }));

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter") {
        e.preventDefault(); // Prevents the new line from being added
        if (inputValue.length) {
          sendRequest(inputValue);
        }
      }
    },
    [inputValue]
  );

  const rename = (
    e: ChangeEvent<HTMLInputElement> | KeyboardEvent,
    conversationID: number
  ) => {
    e.stopPropagation();
    setRenameMode(false);
    renameChat({
      conversationID,
      title: newName || "",
    });
    dispatch(
      setChatHistory([
        ...chatsHistory.map((h) =>
          h.conversationID === conversationID
            ? { ...h, title: newName, createdAt: h.createdAt }
            : h
        ),
      ])
    );
    setCurrentConversationIDTitle(newName);
    setNewName(undefined);
  };

  const handleKeyUp = (e: KeyboardEvent, conversationID: number) => {
    if (e.key === "Enter") {
      rename(e, conversationID); // Call your rename function when Enter is pressed
    }
  };

  return (
    <div
      className={`absolute right-0 bg-transparent h-full w-full pointer-events-none flex items-center gap-2 z-20 ${
        loadingResponse || disableLori ? "pointer-events-none" : ""
      }`}
    >
      <div
        className={`pointer-events-auto w-[40px] h-[40px] z-50 transition-width absolute duration-1000 ${
          fullScreen
            ? "right-[101%] -z-20"
            : isOpenChat
            ? `${
                window.innerWidth < 1600 ? "right-[570px]" : "right-[670px]"
              } rotate-180`
            : " right-[14px]"
        }`}
      >
        <div
          className={` w-[40px] h-[40px] z-20 transition-all absolute rounded-full bg-[#69a3f3] motion-safe:animate-[ping_5s_infinite] opacity-75 blur-sm`}
        />
        <div
          className={`cursor-pointer z-20 transition-all duration-300 absolute rounded-full bg-[#212121] border border-[#69a3f3]`}
          onClick={() => dispatch(isOpenChat ? closeChat() : openChat())}
          data-testid="lori-toggle"
        >
          <ChatButton showNotification={messagePending} isOpen={isOpenChat} />
          <ToggleChatIcon />
        </div>
      </div>
      <div
        className={`absolute pointer-events-auto h-full transition-width z-40 duration-1000 right-0 ${
          fullScreen
            ? "w-full !absolute"
            : isOpenChat
            ? window.innerWidth < 1600
              ? "w-[600px]"
              : "w-[700px]"
            : "w-8"
        }`}
      >
        <div className="absolute overflow-hidden left-0 w-8 h-full bg-gradient-to-b from-[#69a3f3] via-[#69a3f3] to-[#69a3f3]">
          <div className="w-full h-full bg-gradient-to-b from-transparent via-[#dbccf4] to-transparent -translate-y-full animate-[shimmer_5s_infinite]"></div>
        </div>
        <div className="bg-auto absolute left-[6px] w-full h-full bg-gradient-to-b from-[#212121] to-[#373737]">
          <div className="w-full h-full absolute opacity-20"></div>
          <div
            className="w-full h-full absolute opacity-60"
            style={{
              backgroundImage: `url(${StarsBg})`,
            }}
          ></div>
          <div
            className={`flex overflow-hidden flex-col relative shadow-lg shadow-blue-gray-500/30 !border-blue-gray-500 mr-[-15px] h-full w-full transition-opacity duration-1000 delay-500 ${
              isOpenChat ? "opacity-1" : "opacity-0"
            }`}
          >
            <div
              className={`bg-gradient-to-b from-transparent via-[#69a3f3] to-[#5ad1cd] blur-3xl w-24 h-[200%] absolute -translate-y-full ${
                fullScreen ? "right-[100%]" : "right-[720px]"
              } ${
                messagePending
                  ? "animate-[shimmer_3s_infinite]"
                  : "motion-safe:animate-[shimmer_5s_infinite]"
              }`}
            ></div>

            {(loadingResponse || disableLori) && (
              <div className="-z-0 bg-gradient-to-t from-white/40 via-[#58a9ff]/20 to-transparent blur-2xl w-[200%] h-[300px] absolute bottom-[-330px] -translate-y-full motion-safe:animate-[pulse_2s_infinite]"></div>
            )}

            <div
              className={`flex flex-col h-full justify-between ${
                isOpenChat ? "" : "opacity-0"
              }`}
            >
              <div
                className={`flex self-center gap-2 justify-center items-center p-2 w-11/12 h-[76px] min-h-[76px] ${
                  chatMode === "newChat" &&
                  conversationID &&
                  currentConversationIDTitle
                    ? "!border-transparent"
                    : ""
                }`}
              >
                <ChatLogoSymbolIcon width={120} height={56} className="mt-5" />
                <div className="flex items-center justify-between w-full">
                  <div className="text-white font-normal text-[16px] pt-2 my-0" />
                  <div className="text-white font-extralight text-[11px] flex mr-10">
                    <div
                      className={`border-b-2 cursor-pointer ${
                        chatMode === "chats"
                          ? "border-b-white"
                          : "border-b-transparent"
                      } ${
                        isLoadingChatsList
                          ? "pointer-events-none opacity-60"
                          : ""
                      } px-3 pt-5 pb-1`}
                      onClick={showChatsList}
                    >
                      Chats
                    </div>
                    <div
                      className={`border-b-2 cursor-pointer ${
                        chatMode !== "chats"
                          ? "border-b-white"
                          : "border-b-transparent"
                      } px-3 pt-5 pb-1`}
                      onClick={() => {
                        dispatch(setConversationID(0));
                        setChatMode("newChat");
                      }}
                    >
                      New chat
                    </div>
                    <div
                      className="absolute top-0 right-5 text-white h-7 w-7 mt-8 mr-2 cursor-pointer"
                      onClick={() => setFullScreen((prev) => !prev)}
                    >
                      {fullScreen ? <CollapseIcon /> : <ExpandIcon />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mx-8 mt-2 border-b-2 border-b-white/30">
                <div
                  className={`flex justify-between items-center text-white w-full overflow-ellipsis mt-[-20px] py-4 transition-all  ease-in-out duration-500 ${
                    conversationID && currentConversationIDTitle
                      ? "max-h-fit opacity-100"
                      : "max-h-0 opacity-0 pointer-events-none"
                  }`}
                >
                  <div
                    className={`rounded-full p-1 bg-black/50 hover:bg-black/70 cursor-pointer ${
                      isLoadingChatsList ? "pointer-events-none opacity-60" : ""
                    }`}
                    onClick={showChatsList}
                  >
                    <BackIcon />
                  </div>
                  <div className="w-full px-4 flex gap-2 justify-between items-center">
                    {renameMode ? (
                      <>
                        <input
                          type="text"
                          value={newName}
                          onChange={(e) => setNewName(e.target?.value)}
                          onKeyUp={(e) => handleKeyUp(e, conversationID || 0)}
                          onClick={(e) => e.stopPropagation()}
                          autoFocus
                          className="border rounded px-1 py-0.5 focus:outline-none border-none bg-transparent text-white w-full text-ellipsis"
                        />
                        <div className="flex gap-2 items-center">
                          <CloseIcon
                            className="rounded-full border h-5 w-5 p-[3px] opacity-60 hover:opacity-100"
                            onClose={(e) => {
                              e.stopPropagation();
                              setRenameMode(false);
                              setNewName(undefined);
                            }}
                          />
                          <CheckIcon
                            className="rounded-full border h-5 w-5 p-[3px] opacity-60 hover:opacity-100"
                            onClick={(e) => rename(e, conversationID || 0)}
                          />
                        </div>
                      </>
                    ) : (
                      currentConversationIDTitle
                    )}
                  </div>
                  <>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenPopup(true);
                      }}
                      className={`cursor-pointer rounded-full justify-center items-center flex`}
                    >
                      <DrilldownIcon color="#ffffff" hoverColor="#000000" />
                      {openPopup ? (
                        <>
                          <div
                            className={`fixed w-full h-full top-0 left-0 z-50`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenPopup(false);
                            }}
                          ></div>
                          <div
                            className={`absolute top-28 right-4 flex flex-col rounded-md bg-secondary-gray-600 border border-white/20 shadow-3xl px-4 py-2 whitespace-nowrap z-50`}
                          >
                            <div
                              className="flex gap-1 my-[6px] items-center text-white/70 hover:text-white"
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenPopup(false);
                                setNewName(currentConversationIDTitle);
                                setRenameMode(true);
                              }}
                            >
                              <EditIcon /> Rename
                            </div>
                            <div
                              className="flex gap-1 items-center text-white/70 hover:text-white"
                              onClick={(e) =>
                                handleDeleteChat(e, conversationID || 0)
                              }
                            >
                              <DeleteIcon /> Delete
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                </div>
              </div>
              <div
                ref={messagesRef}
                className="flex flex-col m-top-auto flex-grow overflow-auto p-5 pr-10 z-20"
              >
                {chatMode !== "chats" ? (
                  isLoadingChat ? (
                    <div className="w-full h-full text-white/70 animate-pulse p-4">
                      Loading chat...
                    </div>
                  ) : (
                    <>
                      <>
                        {oldM?.map((message, index) =>
                          Array.isArray(message.content) ? (
                            message.content.map((subMessage) => (
                              <ChatMessage
                                key={index}
                                role={message.role}
                                index={index}
                                content={subMessage}
                                isFile={message.isFile}
                                time={message.time}
                                setTyped={setTyped}
                                typingRef={typingRef}
                                isLast={index === oldM.length - 1}
                                typeMessage={message.typeMessage}
                              />
                            ))
                          ) : (
                            <ChatMessage
                              key={index}
                              role={message.role}
                              index={index}
                              content={message.content}
                              isFile={message.isFile}
                              time={message.time}
                              setTyped={setTyped}
                              typingRef={typingRef}
                              isLast={index === oldM.length - 1}
                              typeMessage={message.typeMessage}
                            />
                          )
                        )}{" "}
                      </>
                      <>
                        {loadingResponse ? (
                          <div>
                            <div className="flex gap-3 text-white/80 items-center">
                              <div
                                className={`flex justify-center items-center`}
                              >
                                <div
                                  className="w-full flex justify-center p-3g"
                                  key={"loading"}
                                >
                                  <ChatLogoSymbolIcon />
                                </div>
                              </div>
                            </div>
                            <div
                              className={`px-2 !py-0 flex flex-col bg-black/80
          text-white w-fit m-2 mb-6 min-h-[44px] rounded-xl animate-fadeIn
          `}
                            >
                              <div className="self-start text-left  font-extralight text-sm px-2">
                                <img src={Loader} className="!my-3 w-5" />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    </>
                  )
                ) : conversationID ? (
                  <>
                    <>
                      {oldM?.map((message, index) =>
                        Array.isArray(message.content) ? (
                          message.content.map((subMessage) => (
                            <ChatMessage
                              key={index}
                              role={message.role}
                              index={index}
                              content={subMessage}
                              isFile={message.isFile}
                              time={message.time}
                              setTyped={setTyped}
                              typingRef={typingRef}
                              isLast={index === oldM.length - 1}
                              typeMessage={message.typeMessage}
                            />
                          ))
                        ) : (
                          <ChatMessage
                            key={index}
                            role={message.role}
                            index={index}
                            content={message.content}
                            isFile={message.isFile}
                            time={message.time}
                            setTyped={setTyped}
                            typingRef={typingRef}
                            isLast={index === oldM.length - 1}
                            typeMessage={message.typeMessage}
                          />
                        )
                      )}{" "}
                    </>
                    <>
                      {loadingResponse ? (
                        <div>
                          <div className="flex gap-3 text-white/80 items-center">
                            <div className={`flex justify-center items-center`}>
                              <div
                                className="w-full flex justify-center p-3g"
                                key={"loading"}
                              >
                                <ChatLogoSymbolIcon />
                              </div>
                            </div>
                          </div>
                          <div
                            className={`px-2 !py-0 flex flex-col
           w-fit m-2 mb-6 rounded-xl animate-fadeIn
          `}
                          >
                            <div className="self-start text-left  font-extralight text-sm px-2">
                              <img src={Loader} className="!my-3 w-5" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  </>
                ) : (
                  ChatsList
                )}
              </div>
              {chatMode !== "chats" && (
                <div className="flex gap-2 items-center justify-between p-4 border-t border-t-white/60 w-11/12 self-center h-[66px] rounded-b-md shadow-md text-white">
                  <div className="flex items-center gap-3 w-full">
                    <FileUploader
                      handleFile={(file) => {
                        setFileToUpload(file);
                      }}
                    />
                    <div className="flex w-full h-full rounded-3xl p-3 px-6 text-white bg-black/30">
                      {fileToUpload ? (
                        <div className=" w-full h-full outline-none !border-0 focus:border-transparent bg-transparent text-sm">
                          <FilePreview
                            fileToUpload={fileToUpload}
                            deleteFile={() => setFileToUpload(null)}
                          ></FilePreview>
                        </div>
                      ) : (
                        <textarea
                          ref={inputRef}
                          rows={1}
                          style={{ maxHeight: "10rem", lineHeight: "1.2rem" }}
                          className=" w-full h-fit outline-none !border-0 focus:border-transparent bg-transparent text-sm resize-none overflow-auto rounded-md"
                          placeholder="Ask or search anything..."
                          onKeyDown={handleKeyDown}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                            e.target.style.height = "";
                            e.target.style.height =
                              Math.min(e.target.scrollHeight, 80) + "px";
                          }}
                          value={inputValue}
                          data-testid="lori-input"
                        />
                      )}
                    </div>
                  </div>
                  <SendIcon
                    className={`text-white rounded-full bg-black/30 w-10 h-10 p-2  ${
                      !inputValue?.length && !fileToUpload
                        ? "pointer-events-none opacity-60"
                        : "cursor-pointer"
                    }`}
                    onClick={() => sendRequest(inputValue)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
