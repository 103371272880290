import { useState } from "react";
import {
  getChatsHistory,
  getDrilldown,
  getDrilldownExport,
  getDrilldownWithContent,
} from "../api/api";
import { DrilldownIcon, ExportIcon, LogoSymbolIcon } from "./Icons";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  addMessage,
  openChat,
  setChatHistory,
  setConversationID,
} from "../features/messages/messages-slice";
import { InsightLevel, ROLE } from "../models/models";
import { parseDrilldownText } from "../utils/drilldownParser";

export enum IconSize {
  "small",
  "large",
}
export enum DrilldownType {
  "MAINTENANCE",
  "RENOVATIONS",
  "TRAFFIC_APPLICANT",
  "OCCUPANCY",
  "UTILITIES",
  "DELINQUENCY",
  "BUDGET",
  "COLLECTION",
  "RENEWALS",
  "LOSS_TO_LEASE",
  "EXPIRED_LEASES",
}
type DrilldownProps = {
  className?: string;
  id: string;
  type?: DrilldownType;
  context?: any; //DrilldownResponseType | null;
  size?: IconSize;
  title?: string;
  drilldownExportID?: string;
  drilldownExportTitle?: string;
  drilldownDate?: string;
  inputContent?: {
    title: string;
    content: string;
    entityIds?: string[];
    insightLevel?: InsightLevel;
  };
};

export const Drilldown = ({
  className,
  id,
  type,
  context,
  size,
  title,
  drilldownExportID,
  drilldownExportTitle,
  drilldownDate,
  inputContent,
}: DrilldownProps) => {
  const dispatch = useAppDispatch();
  const [isPopupIsible, setIsPopupVisible] = useState(false);
  const conversationID = useAppSelector(
    (state) => state.messages.conversationID
  );

  const drillDownWithLori = async (context: any, type?: DrilldownType) => {
    if (context === null && id != "0") return;
    const date = new Date();

    dispatch(openChat());
    let content;
    if (inputContent) {
      const resp = await getDrilldownWithContent({
        drilldownID: Number(id) || undefined,
        conversationID,
        inputContent,
        documentID: drilldownExportID ? Number(drilldownExportID) : undefined,
      });

      dispatch(setConversationID(resp.conversation_id));
      dispatch(setChatHistory(await getChatsHistory()));

      resp.drilldown_text &&
        dispatch(
          addMessage({
            role: ROLE.ASSISTANT,
            content: parseDrilldownText([resp.drilldown_text]),
            time: date,
            typeMessage: true,
          })
        );

      resp.user_context_message &&
        dispatch(
          addMessage({
            role: ROLE.ASSISTANT,
            content: parseDrilldownText([resp.user_context_message]),
            time: date,
            typeMessage: true,
          })
        );

      return;
    } else {
      if (!id || id == "0") {
        content = <></>;
        if (context?.customeMessage) {
          content = context?.customeMessage;
        } else {
          content = context?.message?.length
            ? context?.message?.map(
                (m: string | JSX.Element, index: number) => (
                  <div key={`d_${index}`}>
                    {typeof m === "string" && m.search(/[-|-|–|-]/) > -1
                      ? m.split(/[–|-|–|-]/).map((l, index) => (
                          <span
                            key={`dl_${index}`}
                            className={`${
                              index === 0
                                ? "font-semibold after:content-['-']"
                                : ""
                            }`}
                          >
                            {l}
                          </span>
                        ))
                      : m}
                  </div>
                )
              )
            : "Ask me further, what would you like to know?";
        }
      } else {
        const _content = await getDrilldown(id, type);
        content = _content;
      }
    }

    dispatch(
      addMessage({
        role: ROLE.ASSISTANT,
        content,
        time: date,
        typeMessage: true,
      })
    );
  };

  return (
    <>
      <div
        className={`${
          isPopupIsible ? "" : "pointer-events-none cursor-default"
        } fixed w-full h-full top-0 left-0 z-50`}
        onClick={() => setIsPopupVisible(false)}
      ></div>
      <div
        onClick={() => setIsPopupVisible((prev) => !prev)}
        className={` ${className || ""} cursor-pointer absolute ${
          size === IconSize.small
            ? "top-0 mt-[-5px] mr-[-15px] right-0 w-4 h-4"
            : "top-4 right-4 w-8 h-8 border-[1px] border-secondary-gray-350 bg-white"
        } rounded-full hover:bg-secondary-gray-350 flex justify-center items-center`}
      >
        <DrilldownIcon />
        {isPopupIsible && (
          <div
            className={`absolute flex flex-col gap-2 rounded-md bg-white shadow-3xl p-4 ${
              size === IconSize.small ? "top-5 right-3" : "top-7 right-7"
            }  whitespace-nowrap z-50`}
          >
            <div
              className="flex gap-1"
              onClick={() => drillDownWithLori(context, type)}
            >
              <LogoSymbolIcon width={24} height={24} />
              {title || "Show me more"}
            </div>
            {drilldownExportID ? (
              <div
                className="flex gap-1 my-[6px]"
                onClick={() =>
                  getDrilldownExport(
                    drilldownExportID,
                    drilldownExportTitle || "DRILLDOWN",
                    drilldownDate || new Date().toString()
                  )
                }
              >
                <ExportIcon className="mt-[3px] mx-[6px]" />
                Export csv
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
};
