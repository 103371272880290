export type AnyObjectWithId = {
  id: number | string;
  [key: string]: any; // Allow any number of additional properties of any type
};

export type InsightDTO = {
  id: string;
  type: "notification" | "insight";
  title: string;
  message: string;
  details?: string[] | null;
  isDone: boolean;
  createdAt: Date;
  priority?: number;
  drilldownID?: string;
  drilldownExportID?: string;
  entityIds?: string[];
  insightLevel: InsightLevel;
};

export type insightsDTO = {
  isInitialState: boolean;
  data: insightsDTO[];
};

export type DrilldownContextDTO = {
  drilldownID?: number;
  documentID?: number;
  conversationID: number;
  inputContent: {
    title: string;
    content?: string;
    entityIds?: string[];
    insightLevel?: InsightLevel;
  };
};

export type DrilldownContextResponseDTO = {
  conversation_id: number;
  user_context_message: string;
  drilldown_text?: string;
};

type PortfolioData = {
  lastUpdatedAt: Date;
  noi: { value: number | null; trend: number | null };
  occupancy: { value: number | null; trend: number | null };
  delinquency: {
    actual: number | null;
    grossPotentialRent: number | null;
    previousActual: number | null;
    previousGrossPotentialRent: number | null;
  };
  budget: { planned: number | null; actual: number | null };
  turnovers: number | null;
  expectedRenovations: {
    count: number | null;
    totalCost: number | null;
  };
};

export type PropertyData = {
  id: string;
  lastUpdatedAt: Date;
  date?: string | null;
  address?: string | null;
  name: string;
  unitsCount?: number;
  occupancy: {
    drilldownID: string;
    drilldownExportID?: string;
    value: number | null;
    trend: number | null;
  };
  delinquency: {
    drilldownID: string;
    drilldownExportID?: string;
    actual: number | null;
    grossPotentialRent: number | null;
    previousActual: number | null;
    previousGrossPotentialRent: number | null;
  };
  budget: {
    drilldownID: string;
    drilldownExportID?: string;
    planned: number | null;
    actual: number | null;
  };
  maintenanceExpenses: {
    drilldownID: string;
    drilldownExportID?: string;
    value: number | null;
    trend: number | null;
  };
  utilitiesExpenses: {
    drilldownID: string;
    drilldownExportID?: string;
    current: number | null;
    previous: number | null;
  };
  collection: {
    drilldownID: string;
    drilldownExportID?: string;
    value: number | null;
    target: number | null;
  };
  renewals: {
    drilldownID: string;
    drilldownExportID?: string;
    nonConfirmedMoveouts: number | null;
    confirmedRenewals: number | null;
    turnovers: number | null;
  };
  renovations: {
    drilldownID: string;
    drilldownExportID?: string;
    value: number | null;
    trend: number | null;
  };
  // leaseRatio: {
  //   drilldownID: string;
  //   visitors: number | null;
  //   applicants: number | null;
  //   signedLeases: number | null;
  // };
  expiredLeases: {
    drilldownID: string;
    drilldownExportID?: string;
    value: number | null;
  };
  lossToLease: {
    drilldownID: string;
    drilldownExportID?: string;
    charged: number | null;
    marketValue: number | null;
  };
  noi: {
    drilldownID: string;
    drilldownExportID?: string;
    value: number | null;
    trend: number | null;
  };
  granularity?: string;
};

export type CalculationMethod = "accrual" | "cash" | "hybrid";
export type MetricCalculationMethodsDTO = {
  noi?: CalculationMethod;
  collection?: CalculationMethod;
  maintenanceExpenses?: CalculationMethod;
  expenses?: CalculationMethod;
  utilitiesExpenses?: CalculationMethod;
};

export type CalculationMethodDTO = {
  method: CalculationMethod;
  metricCalculationMethods?: MetricCalculationMethodsDTO;
};

export type PropertiesDTO = PropertyData[];

export type PropertiesOverallDTO = PortfolioData;

export type ChatHistoryEntry = {
  conversationID: number;
  title: string;
  createdAt: Date;
};

export type ChatsHistoryDTO = ChatHistoryEntry[];

export type DecisionTreeBranchDTO = {
  title: string;
  steps: string[];
};

export type DecisionTreeDTO = {
  positive: DecisionTreeBranchDTO;
  negative: DecisionTreeBranchDTO;
};

export type NotificationDTO = {
  id: string;
  title: string;
  description: string;
  isDone: boolean;
};

export enum ROLE {
  ASSISTANT = "assistant",
  USER = "user",
  SYSTEM = "system",
  TOOL = "tool",
}

// export type ChatMessageDTO = {
//   _key?: string;
//   role: ROLE;
//   content: string | JSX.Element;
//   time?: string;
//   index?: number;
//   typeMessage?: boolean;
//   name?: string;
//   tool_call_id?: string;
//   tool_calls?: any;
//   typingRef?: any;
//   setTyped?: any;
//   isLast?: boolean;
// };

/**
 * New message content is a list of LoriMessageWithAttachment,
 * other options are just fallbacks for historical data that might still be in the DB.
 */
export type MessageDTO = {
  _key?: string;
  role: ROLE;
  content: string | JSX.Element | LoriMessageWithAttachment[];
  isFile?: boolean;
  time?: Date;
  index?: number;
  typeMessage?: boolean;
  name?: string;
  typingRef?: any;
  setTyped?: any;
  isLast?: boolean;
};

/**
 * New message content is a list of LoriMessageWithAttachment,
 * other options are just fallbacks for historical data that might still be in the DB.
 */
export type ChatMessageDTO = {
  _key?: string;
  role: ROLE;
  content: string | JSX.Element | LoriMessageWithAttachment;
  isFile?: boolean;
  time?: Date;
  index?: number;
  typeMessage?: boolean;
  name?: string;
  typingRef?: any;
  setTyped?: any;
  isLast?: boolean;
};

export type Contact = {
  name: string;
  email: string;
};

export type ContactGroups = {
  [key in REPORT_TYPE]: Contact[];
};

export type ReportGroups = {
  [key in REPORT_TYPE]: boolean;
};

export type FileType = "pdf" | "xsl" | "xlsx" | "csv" | "other";

export type ReportLink = {
  title: string;
  date?: Date;
  url: string;
  fileType?: FileType;
  executiveSummary?: string;
};

export type SavedReportsDTO = {
  [value in REPORT_TYPE]: ReportLink[];
};

export enum REPORT_TYPE {
  lp = "LP",
  internal = "INTERNAL",
}

export type REPORT_TYPE_VALUE = `${Extract<
  REPORT_TYPE,
  string
>}` extends `${infer R extends string}`
  ? R
  : never;

export type UserConfigDTO = {
  metricCalculationMethods?: MetricCalculationMethodsDTO;
  granularity?: PortfolioGranularityType;
};

export type User = {
  id: string;
  first_login?: boolean;
  calc_method?: boolean;
  createdNotifications?: boolean;
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  [key: string]: any;
  contactGroups?: ContactGroups;
  config: UserConfigDTO;
};

export type LoriMessageWithAttachment = {
  type: "text" | "graph" | "csv_file";
  content: string;
  filename?: string;
};

export type LoriResponseDTO = {
  conversationID: number;
  messages: string[] | LoriMessageWithAttachment[][];
  attachments?: File[];
};

export type GenerateReportDTO = {
  reportType: REPORT_TYPE;
  properties: string;
  fromDate: Date;
  toDate: Date;
  recepients: string[];
};

export type RegisterInsightDTO = {
  message: string;
  title?: string;
  priority?: number;
};

export type FeedbackDTO = {
  sectionName: string;
  input: string;
};

export type InsightLevel = "property" | "property_group";

export type AxisType = "category" | "datetime" | "numeric";

export const GraphTypes = ["bar", "line"] as const; //, "pie", "donut"
export type GraphType = (typeof GraphTypes)[number];

export const PortfolioGranularityTypes = ["month", "quarter", "year"] as const;

export const GranularityTypes = ["week", ...PortfolioGranularityTypes] as const;

export type GranularityType = (typeof GranularityTypes)[number];
export type PortfolioGranularityType =
  (typeof PortfolioGranularityTypes)[number];
export const BreakDownOptions: { [key in GranularityType]: string } = {
  week: "Weekly",
  month: "Monthly",
  quarter: "Quarterly",
  year: "Yearly",
};
export const PortfolioBreakDownOptions: {
  [key in PortfolioGranularityType]: string;
} = {
  month: "Monthly",
  quarter: "Quarterly",
  year: "Yearly",
};

export enum MetricFilter {
  DATE_RANGE = "date_range",
  GRANULARITY = "granularity",
  CHRT_TYPE = "chart_type",
  PROPERTIES = "properties",
  CUSTOME = "specific_category_items",
}
export type MetricFilterType =
  | "date_range"
  | "granularity"
  | "chart_type"
  | "properties"
  | "specific_category_items";

export type MetricGraph = {
  graph_name: string;
  text: string;
  optional_params: MetricFilterType[];
};

export type GraphRequestDTO = {
  track: boolean;
  date_range: Date[];
  granularity: GranularityType;
  properties: string[];
  graph_name: string;
  chart_type: GraphType;
  metrics: boolean;
  per_property?: boolean;
};

export type TrendGraphDTO = {
  id: string;
  series: any;
  title?: string;
  properties?: string[];
  options: {
    chart: any;
    xaxis: any;
    yaxis: any;
  };
  chart_type: GraphType;
  track?: boolean;
  granularity?: GranularityType;
  status?: "calculating" | "ready";
  per_property?: boolean;
};

export type CustomTrendDTO = {
  selectedProperties: string[];
  selectedUnits: string[];
  selectedMetrics: string[];
  fromDate: Date;
  toDate?: Date;
  track?: boolean;
};

export type DecisionTreeData = {
  title: string;
  message: string;
};

export type RentalDataDTO = {
  address: string;
  latitude: number;
  longitude: number;
  baths?: string;
  bedrooms?: number;
  distance?: number;
  dollar_sqft?: number;
  is_search_address?: boolean;
  last_seen?: string;
  price?: number;
  property_type?: string;
  sqft?: number;
};

export type DrilldownResponseDTO = {
  message: string;
};

export type InsightDrilldownDTO = {
  insight: string;
};

export type DashboardDrilldownDTO = {
  dashboard: TrendGraphDTO;
};

export type MaintenanceDrilldownDTO = {
  accountID: number;
  accountName: string;
  amount: number;
}[];

export type RenovationsDrilldownDTO = {
  unitID: number;
  unitNumber: string;
}[];

export type OccupancyDrilldownDTO = {
  unitType: string;
  status: "Rented" | "Vacant" | "RentedVacant";
  number: number;
  percentage: number;
}[];

export type UtilitiesDrilldownDTO = {
  electricity: {
    unitID: number;
    unitNumber: string;
    current: number;
    previous: number;
  }[];
  gas: {
    unitID: number;
    unitNumber: string;
    current: number;
    previous: number;
  }[];
  water: {
    unitID: number;
    unitNumber: string;
    current: number;
    previous: number;
  }[];
  other: {
    unitID: number;
    unitNumber: string;
    current: number;
    previous: number;
  }[];
};

export type DelinquencyDrilldownDTO = {
  unitID: number;
  unitNumber: string;
  period: string;
  amount: number;
}[];

export type BudgetDrilldownDTO = {
  accountID: number;
  accountName: string;
  actual: number;
  planned: number;
}[];

export type CollectionDrilldownDTO = {
  date: Date;
  accumulativePrecentage: number;
}[];
export type TrafficApplicantsDrilldownDTO = {
  topTracted: {
    unitID: number;
    unitNumber: string;
    visits: number;
    applications: number;
  }[];
  leastTracted: {
    unitID: number;
    unitNumber: string;
    visits: number;
    applications: number;
  }[];
};

export type RenewalsDrilldownDTO = {
  propertyID: number;
  date: Date;
  count: number;
  unitType: string;
  tenantChange: string;
  percentage: number;
}[];

export type LossToLeaseDrilldownDTO = {
  unitID: number;
  unitNumber: string;
  amount: number;
  marketRent: number;
  lossRatio: number;
  loss: number;
}[];

export type DrilldownResponseType =
  | TrafficApplicantsDrilldownDTO
  | CollectionDrilldownDTO
  | BudgetDrilldownDTO
  | DelinquencyDrilldownDTO
  | UtilitiesDrilldownDTO
  | OccupancyDrilldownDTO
  | RenovationsDrilldownDTO
  | MaintenanceDrilldownDTO
  | RenewalsDrilldownDTO
  | LossToLeaseDrilldownDTO
  | DashboardDrilldownDTO
  | InsightDrilldownDTO
  | DrilldownResponseDTO;
