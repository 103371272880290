import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ChangeEvent, useEffect, useState } from "react";
import { ChatHistoryEntry, ChatsHistoryDTO } from "../models/models";
import { useAppDispatch } from "../app/hooks";
import { getChatsHistory, renameChat } from "../api/api";
import {
  setChatHistory,
  setConversationID,
} from "../features/messages/messages-slice";
import { CloseIcon, DeleteIcon, DrilldownIcon, EditIcon } from "./Icons";
import { CheckIcon } from "@heroicons/react/24/outline";

dayjs.extend(utc);
dayjs.extend(timezone);

type GroupedDatesDTO = {
  today: ChatHistoryEntry[];
  thisWeek: ChatHistoryEntry[];
  lastWeek: ChatHistoryEntry[];
  thisMonth: ChatHistoryEntry[];
  lastMonth: ChatHistoryEntry[];
  thisYear: ChatHistoryEntry[];
  lastYear: ChatHistoryEntry[];
};

const groupMessagesByDate = (newChatHistory: ChatsHistoryDTO) => {
  const today = dayjs();

  const grouped: GroupedDatesDTO = {
    today: [],
    thisWeek: [],
    lastWeek: [],
    thisMonth: [],
    lastMonth: [],
    thisYear: [],
    lastYear: [],
  };

  Object.entries(newChatHistory).forEach((chat) => {
    const createdAt = dayjs.utc(chat[1].createdAt).local();
    const msg = {
      conversationID: chat[1].conversationID,
      title: chat[1].title,
      createdAt: chat[1].createdAt,
    };

    if (createdAt.isSame(today, "day")) {
      grouped.today.push(msg);
    } else if (createdAt.isSame(today, "week")) {
      grouped.thisWeek.push(msg);
    } else if (createdAt.isSame(today.subtract(1, "week"), "week")) {
      grouped.lastWeek.push(msg);
    } else if (createdAt.isSame(today, "month")) {
      grouped.thisMonth.push(msg);
    } else if (createdAt.isSame(today.subtract(1, "month"), "month")) {
      grouped.lastMonth.push(msg);
    } else if (createdAt.isSame(today, "year")) {
      grouped.thisYear.push(msg);
    } else if (createdAt.isSame(today.subtract(1, "year"), "year")) {
      grouped.lastYear.push(msg);
    }
  });

  return grouped;
};

export const ListOfChats = ({
  chatsHistory,
  setCurrentConversationID,
  setIsLoading,
  onDelete,
}: {
  chatsHistory: ChatsHistoryDTO;
  setCurrentConversationID: (cid: number) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    conversationID: number
  ) => void;
}) => {
  const dispatch = useAppDispatch();
  const [groupedMessages, setGroupedMessages] = useState<GroupedDatesDTO>();
  const [activeIndex, setActiveIndex] = useState<string>();
  const [renameMode, setRenameMode] = useState<string>();
  const [newName, setNewName] = useState<string>();

  useEffect(() => {
    const getData = async () => {
      let history = chatsHistory;
      try {
        if (!(chatsHistory && Object.keys(chatsHistory).length)) {
          setIsLoading(true);
          history = await getChatsHistory();
        }
        dispatch(setChatHistory(history));
        setChatHistory(history);
        setGroupedMessages(groupMessagesByDate(history));
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [chatsHistory]);

  const rename = (
    e: ChangeEvent<HTMLInputElement> | KeyboardEvent,
    conversationID: number,
    title = ""
  ) => {
    e.stopPropagation();
    setRenameMode(undefined);
    renameChat({
      conversationID,
      title,
    });
    dispatch(
      setChatHistory([
        ...chatsHistory.map((p: ChatHistoryEntry) =>
          p.conversationID === conversationID
            ? { conversationID, title, createdAt: p.createdAt }
            : p
        ),
      ])
    );
    setNewName(undefined);
  };

  const handleEditChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target?.value);
  };

  const handleKeyUp = (e: KeyboardEvent, conversationID: number) => {
    if (e.key === "Enter") {
      rename(e, conversationID, e.target?.value); // Call your rename function when Enter is pressed
    }
  };

  const showChat = (chat: ChatHistoryEntry) => {
    setIsLoading(true);
    setCurrentConversationID(chat.conversationID);
    dispatch(setConversationID(chat.conversationID));
  };

  const renderGroup = (title: string, chats: ChatHistoryEntry[]) => {
    if (chats.length === 0) return null;
    return (
      <div key={title} className="mb-1">
        <div>{title}</div>
        <div className="flex flex-col p-2">
          {chats.map((chat, idx) => (
            <div
              key={chat.conversationID}
              className={`group relative rounded-md py-2 px-3 text-white/60 hover:bg-white/10 hover:text-white whitespace-nowrap ${
                activeIndex === `${title}_${idx}`
                  ? "bg-white/10 text-white"
                  : ""
              } ${
                renameMode === `${title}_${idx}` ? "hover:!bg-transparent" : ""
              } cursor-pointer flex justify-between items-center`}
              onClick={() => showChat(chat)}
            >
              {renameMode === `${title}_${idx}` ? (
                <>
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => handleEditChange(e)}
                    onKeyUp={(e) => handleKeyUp(e, chat.conversationID)}
                    onClick={(e) => e.stopPropagation()}
                    autoFocus
                    style={{ width: "calc(100% - 100px)" }}
                    className="border rounded px-1 py-0.5 focus:outline-none border-none bg-transparent text-white"
                  />
                  <CloseIcon
                    className="rounded-full border h-5 w-5 p-[3px] opacity-60 hover:opacity-100"
                    onClose={(e) => {
                      e.stopPropagation();
                      setRenameMode(undefined);
                      setNewName(undefined);
                    }}
                  />
                  <CheckIcon
                    className="rounded-full border h-5 w-5 p-[3px] opacity-60 hover:opacity-100"
                    onClick={(e) =>
                      rename(e, chat.conversationID, newName || "")
                    }
                  />
                </>
              ) : (
                <div className="w-full overflow-hidden overflow-ellipsis group-hover:!w-4/5">
                  {chat.title}
                </div>
              )}
              <>
                <div
                  className={`${
                    activeIndex === `${title}_${idx}`
                      ? ""
                      : "pointer-events-none cursor-default"
                  } fixed w-full h-full top-0 left-0 z-50`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveIndex(undefined);
                  }}
                ></div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveIndex(`${title}_${idx}`);
                  }}
                  className={`cursor-pointer rounded-full justify-center items-center hidden ${
                    activeIndex === `${title}_${idx}` ? "flex" : ""
                  } group-hover:flex ${
                    renameMode === `${title}_${idx}` ? "!hidden" : ""
                  }`}
                >
                  <DrilldownIcon color="#ffffff" hoverColor="#000000" />
                  {activeIndex === `${title}_${idx}` && (
                    <div
                      className={`absolute top-12 right-2 flex flex-col rounded-md bg-secondary-gray-600/80 border border-white/20 shadow-3xl px-4 py-2 whitespace-nowrap z-50`}
                    >
                      <div
                        className="flex gap-1 my-[6px] items-center text-white/70 hover:text-white"
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveIndex(undefined);
                          setNewName(chat.title);
                          setRenameMode(`${title}_${idx}`);
                        }}
                      >
                        <EditIcon /> Rename
                      </div>
                      <div
                        className="flex gap-1 items-center text-white/70 hover:text-white"
                        onClick={(e) => {
                          onDelete(e, chat.conversationID);
                          setActiveIndex(undefined);
                          dispatch(
                            setChatHistory([
                              ...chatsHistory.filter(
                                (p: ChatHistoryEntry) =>
                                  p.conversationID !== chat.conversationID
                              ),
                            ])
                          );
                        }}
                      >
                        <DeleteIcon /> Delete
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-2 m-6 text-white">
      {chatsHistory?.length === 0 ? (
        <div className="text-white/40">No chats created yet.</div>
      ) : groupedMessages ? (
        <>
          {renderGroup("Today", groupedMessages.today)}
          {renderGroup("This Week", groupedMessages.thisWeek)}
          {renderGroup("Last Week", groupedMessages.lastWeek)}
          {renderGroup("This Month", groupedMessages.thisMonth)}
          {renderGroup("Last Month", groupedMessages.lastMonth)}
          {renderGroup("This Year", groupedMessages.thisYear)}
          {renderGroup("Last Year", groupedMessages.lastYear)}
        </>
      ) : (
        <div className="animate-pulse">Loading chats...</div>
      )}
    </div>
  );
};
