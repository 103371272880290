import { useCallback, useEffect, useMemo, useState } from "react";
import { Insight } from "./Insight";
import { DecisionTreeData, InsightDTO } from "../models/models";
import { SmallText, Title } from "../styles/Typography";
import { FilterDropdown } from "./FilterDropdown";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getInsights, registerInsight } from "../api/api";
import { setInsights } from "../features/notifications/notifications-slice";
import { IconMessageLayout } from "./Layouts/IconMessageLayout";
import { CheckCircleIcon, InfoIcon, InsightIcon } from "./Icons";
import { InfoPopup } from "./InfoPopup";
import { FeedbackBox } from "./FeedbackBox";
import { Link, OutlineButton } from "./Buttons";
import { Popup } from "./Popup";
import { SearchBar } from "./SearchBar";
import { DecisionTree } from "./DecisionTree";

const PORTFOLIO_LEVEL = "Portfolio level";

function Insights() {
  const isInitialState = false;
  const dispatch = useAppDispatch();
  const insights = useAppSelector((state) => state.notifications.insights);
  const [loadingInsights, setLoadingInsights] = useState(true);
  const [insightsFilter, setInsightsFilter] = useState<{
    insightTypes: string[];
    properties: string[];
  }>({ insightTypes: [], properties: [] });
  const [insightRegistered, setInsightRegistered] = useState(false);
  const [newInsightPopupVisible, setNewInsightPopupVisible] = useState(false);
  const [showInsightsInfoPopup, setShowInsightsInfoPopup] = useState(false);
  const [decisionTree, setDecisionTree] = useState<DecisionTreeData | null>(
    null
  );

  const properties = useAppSelector((state) => state.properties.properties);

  const isLoading = !properties || properties.length === 0 || loadingInsights;

  const propertiesThatHaveInsights = useMemo(
    () =>
      !isLoading
        ? properties.filter((prop) =>
            insights.some((i) => i.entityIds?.includes(prop.id))
          )
        : [],
    [isLoading, properties, insights]
  );

  const portfolioLevelInsights = useMemo(
    () =>
      !isLoading
        ? insights.some((i) => i.insightLevel === "property_group")
        : [],
    [isLoading, insights]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await getInsights();
        dispatch(setInsights(list));
        setLoadingInsights(false);
      } catch (e) {
        console.error(`Faild to get Insights: ${e}`);
      }
    };

    fetchData();
  }, []);

  const dismissPopup = () => {
    setNewInsightPopupVisible(false);
    setInsightRegistered(false);
  };

  const InsightsFilterList = useCallback(
    ({
      options,
      applyFilter,
      disabled,
    }: {
      options: string[];
      applyFilter: (options: string[]) => void;
      disabled: boolean;
    }) => {
      return (
        <>
          <FilterDropdown
            disabled={disabled}
            options={options}
            applyFilter={applyFilter}
            searchLabel={"insight type"}
          />
        </>
      );
    },
    []
  );

  type registerNewInsightDTO = {
    input: string;
    title?: string;
    priority?: number;
  };

  const registerNewInsight = ({
    input,
    title,
    priority,
  }: registerNewInsightDTO) => {
    setInsightRegistered(true);
    registerInsight({ message: input, title, priority });
  };

  const insightsToPreview = useMemo(() => {
    const isEmptyFilter =
      !insightsFilter.insightTypes?.length &&
      !insightsFilter.properties?.length;

    const _insightsToPreview = isEmptyFilter
      ? insights
      : insights.filter((insight) => {
          const isInsightMatch = insightsFilter.insightTypes?.length
            ? insightsFilter.insightTypes.includes(
                insight.title.indexOf(PORTFOLIO_LEVEL) > -1
                  ? PORTFOLIO_LEVEL
                  : insight.title
              )
            : true;

          const isPortfolioSelected =
            insightsFilter.properties.includes(PORTFOLIO_LEVEL);

          const insightProperties: string[] =
            !!insight.entityIds?.length && insight.insightLevel === "property"
              ? propertiesThatHaveInsights
                  .filter((property) =>
                    insight.entityIds?.includes(property.id)
                  )
                  .map((x) => x.name)
              : [];

          const getIsPropertyMatch = () => {
            if (!insightsFilter.properties?.length) return true;
            if (
              insightProperties.some((propertyName) =>
                insightsFilter.properties.includes(propertyName)
              )
            )
              return true;
            if (
              isPortfolioSelected &&
              insight.insightLevel === "property_group"
            )
              return true;
            return false;
          };

          const isPropertyMatch = getIsPropertyMatch();

          return isPropertyMatch && isInsightMatch;
        });

    const sortedInsights = _insightsToPreview?.length
      ? [..._insightsToPreview].sort((a: InsightDTO, b: InsightDTO) =>
          a.createdAt < b.createdAt ? 1 : -1
        )
      : [];

    return sortedInsights;
  }, [
    insightsFilter.insightTypes,
    insightsFilter.properties,
    insights,
    propertiesThatHaveInsights,
  ]);

  const InsightsList = useMemo(() => {
    if (isLoading) return <div className="px-10">Loading insights ...</div>;
    if (isInitialState)
      return (
        <IconMessageLayout
          icon={<InsightIcon />}
          title={"Your daily insights will appear here"}
          text={
            <>
              Lori is analyzing your portfolio's data in the background. <br />
              Any insights she finds will be displayed here.
            </>
          }
        />
      );

    if (!insights?.length)
      return (
        <IconMessageLayout
          icon={<CheckCircleIcon />}
          title={"Well done!"}
          text={
            <>
              You have checked all the insights. <br /> Lori will keep you
              posted with new ones.
            </>
          }
        />
      );

    return (
      <div className="flex flex-col w-full gap-5 h-full px-10 pb-56 overflow-auto">
        {insightsToPreview.map((insight) => (
          <Insight
            key={insight.id}
            id={insight.id}
            type={insight.type}
            title={insight.title}
            details={insight.details}
            message={insight.message}
            priority={insight.priority}
            createdAt={insight.createdAt}
            isDone={insight.isDone}
            drilldownID={insight.drilldownID}
            drilldownExportID={insight.drilldownExportID}
            entityIds={insight.entityIds}
            insightLevel={insight.insightLevel}
            setDecisionTree={() => {}}
          />
        ))}
      </div>
    );
  }, [isLoading, isInitialState, insights?.length, insightsToPreview]);

  return (
    <>
      <div className="relative z-10 bg-secondary-gray-50 w-[40%] min-w-[388px] border-r-[1px] border-blue-gray-100/30 overflow-hidden h-screen">
        <div className="flex justify-between w-full h-20 px-10 pt-6">
          <div className="flex items-center justify-between gap-3 mb-6 w-full z-2">
            <div className="flex gap-2 items-center">
              <Title className="!m-0">Daily Insights</Title>
              <InfoIcon onClick={() => setShowInsightsInfoPopup(true)} />
              {showInsightsInfoPopup && (
                <InfoPopup
                  dismissPopup={() => setShowInsightsInfoPopup(false)}
                  title={"Insights"}
                  text={`Here you can view daily insights based on your portfolio’s data and mark them as read.
              The insights are organized by property, allowing you to apply filters as per your preference from the top right corner.`}
                />
              )}
              <FeedbackBox sectionName="insights" />
            </div>
            <div className="flex gap-3 items-center">
              <OutlineButton
                onClick={() => setNewInsightPopupVisible(true)}
                className="opacity-50 pointer-events-none"
              >
                + Add Insight
              </OutlineButton>
              <div className="absolute top-[53px] right-[60px] text-[10px] bg-black text-white px-3 rounded-full">
                Coming soon!
              </div>
            </div>
          </div>
          {newInsightPopupVisible && (
            <Popup
              title={"Add insight"}
              closePopup={() => setNewInsightPopupVisible(false)}
              className="top-24 left-44 max-w-xs"
            >
              {insightRegistered ? (
                <>
                  <div className=" bg-green-50 rounded-lg p-2 mb-4">
                    <div className="flex items-center gap-1 justify-start text-green font-semibold">
                      <InfoIcon color="#0D652D" />
                      <div>Insight added successfully!</div>
                    </div>

                    <SmallText className="!mt-2 !ml-6 text-secondary-gray-500">
                      The added insight will be shown if Lori finds something
                      about it.
                    </SmallText>
                  </div>
                  <div className="flex w-full justify-end">
                    <Link onClick={dismissPopup}>Ok! Got it</Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="border-b border-secondary-gray-200 pb-2">
                    <div className="bg-primary-very-light rounded-lg p-2 mb-4">
                      <div className="flex items-center gap-1 justify-start">
                        <InfoIcon />
                        <div className="text-primary font-semibold">
                          Try something like
                        </div>
                      </div>

                      <SmallText className="!m-0 text-secondary-gray-500">
                        “Let me know when the actual budget spend hits the 70%
                        from the plan”.
                      </SmallText>
                    </div>
                  </div>
                  <SearchBar
                    className="mt-4"
                    placeholder="Ask for anything..."
                    search={(input) => registerNewInsight({ input })}
                  />
                </>
              )}
            </Popup>
          )}
        </div>
        <div className="h-full w-full overflow-hidden pt-1">
          <div className="px-10 space-x-2">
            <InsightsFilterList
              disabled={isLoading}
              options={[
                ...new Set(
                  insights.map((i) =>
                    i.title.indexOf(PORTFOLIO_LEVEL) > -1
                      ? PORTFOLIO_LEVEL
                      : i.title
                  )
                ),
              ]}
              applyFilter={(selectedOptions) => {
                setInsightsFilter({
                  ...insightsFilter,
                  insightTypes: selectedOptions,
                });
              }}
            />
            <FilterDropdown
              disabled={isLoading}
              options={
                portfolioLevelInsights
                  ? [
                      PORTFOLIO_LEVEL,
                      ...propertiesThatHaveInsights.map((p) => p.name),
                    ]
                  : [...propertiesThatHaveInsights.map((p) => p.name)]
              }
              applyFilter={(propertiesFilter) =>
                setInsightsFilter({
                  ...insightsFilter,
                  properties: propertiesFilter,
                })
              }
              searchLabel={"property"}
              showSelectAllBtn={false}
            />
          </div>
          {InsightsList ? (
            InsightsList
          ) : (
            <div className="px-10">Loading insights ...</div>
          )}
        </div>
        <div className="w-[38%] h-20 fixed bottom-0 left-0 bg-gradient-to-t from-white pointer-events-none"></div>
      </div>
      {decisionTree ? (
        <DecisionTree
          title={decisionTree.title}
          message={decisionTree.message}
          closePopup={() => setDecisionTree(null)}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Insights;
