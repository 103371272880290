import {
  LargeText,
  SmallText,
  Subtext,
  Subtitle,
  Title,
} from "../styles/Typography";
import { InfoIcon, UpdatedAtIcon } from "@components/Icons";
import { Bubble } from "@components/Bubble";
import { Property } from "@components/Property";
import PortfolioShimmerBg from "../assets/shimmer2.png";
import PropertiesShimmerBg from "../assets/shimmer3.png";
import {
  calculateDelinquencyRate,
  calculateDelinquencyTrend,
  calculatePercentage,
  displayValue,
  sortById,
} from "../utils/helpers";
import { FeedbackBox } from "@components/FeedbackBox";
import { FilterDropdown } from "@components/FilterDropdown";
import {
  setProperties,
  setPropertiesOverall,
} from "../features/properties/properties-slice";
import { InfoPopup } from "@components/InfoPopup";
import { TrendMetric } from "@components/LineMetric";
import { CustomizeCalculationMethod } from "@components/CustomizeCalculationMethod";
import { CustomizePeriod } from "@components/CustomizeGranularityAndPeriod";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getProperties, getPropertiesOverall } from "../api/api";
import {
  GranularityTypes,
  PortfolioGranularityType,
  PortfolioGranularityTypes,
  PropertiesDTO,
} from "../models/models";
import { AppContext } from "../App";

type OverallMetricProps = {
  title: string;
  subtitle?: string;
  value?: number | null;
  prefix?: string;
  suffix?: string;
  trend?: number | null;
  other?: JSX.Element;
  className?: string;
  reverseRiskColor?: boolean;
};

function Properties() {
  const dispatch = useAppDispatch();
  const [propertiesFilter, setPropertiesFilter] = useState<string[]>([]);
  const [showPortfolioInfoPopup, setShowPortfolioInfoPopup] = useState(false);
  const [showPropertiesInfoPopup, setShowPropertiesInfoPopup] = useState(false);
  const [isPeriodLoading, setIsPeriodLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<string>();
  const [selectedGranularity, setSelectedGranularity] =
    useState<PortfolioGranularityType>();
  const [isCalculationMethodLoading, setIsCalculationMethodLoading] =
    useState(false);
  const [pageUpdated, setPageUpdated] = useState(false);
  const fetchingState = useRef({ overall: false, properties: false });

  const properties = useAppSelector((state) =>
    sortById(state.properties?.properties)
  );

  const propertiesOverall = useAppSelector(
    (state) => state.properties.propertiesOverall
  );

  const isFetchingOverall = fetchingState.current.overall === true;
  const isFetchingProperties = fetchingState.current.properties === true;

  // const [isFetchingOverall, setIsFetchingOverall] = useState(false);
  // const [isFetchingProperties, setIsFetchingProperties] = useState(false);

  const shouldShowLoaderByChanges = useMemo(
    () => isCalculationMethodLoading || isPeriodLoading,
    [isPeriodLoading, isCalculationMethodLoading]
  );

  useEffect(() => {
    let isMounted = true; // Track component mount state

    const fetchData = async () => {
      if (fetchingState.current.overall || fetchingState.current.properties) {
        // if (isFetchingOverall || isFetchingProperties) {
        return; // Prevent duplicate requests if already fetching
      }

      try {
        // setIsFetchingOverall(true);
        // setIsFetchingProperties(true);

        const [_overall, _properties] = await Promise.all([
          getPropertiesOverall(selectedPeriod),
          getProperties(selectedPeriod),
        ]);

        if (isMounted) {
          fetchingState.current.overall = true;
          fetchingState.current.properties = true;
          if (_overall) {
            dispatch(setPropertiesOverall({ ..._overall }));
          }
          if (_properties)
            dispatch(setProperties(sortById(_properties) as PropertiesDTO));
        }
      } catch (e) {
        console.error(`Failed to fetch data: ${e}`);
      } finally {
        // fetchingState.current.overall = false;
        // fetchingState.current.properties = false;
        // setIsFetchingOverall(false);
        // setIsFetchingProperties(false);

        if (isMounted) {
          fetchingState.current.overall = false;
          fetchingState.current.properties = false;
          setIsPeriodLoading(false);
          setIsCalculationMethodLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup to prevent state updates on unmount
    };
  }, [isFetchingOverall, isFetchingProperties]); // Keep dependencies minimal

  useEffect(() => {
    if (fetchingState.current.overall || fetchingState.current.properties) {
      // if (isFetchingOverall || isFetchingProperties) {
      return; // Prevent duplicate requests if already fetching
    }
    // let isMounted = true;

    const controller = new AbortController();
    const signal = controller.signal; // This will be used to abort API calls

    if (!pageUpdated) return;
    setPageUpdated(false);

    const updateData = async () => {
      // if (isMounted) {
      fetchingState.current.overall = true;
      fetchingState.current.properties = true;
      // setIsFetchingOverall(true);
      // setIsFetchingProperties(true);
      try {
        const p = await getPropertiesOverall(selectedPeriod, signal);
        dispatch(setPropertiesOverall({ ...p }));
      } catch (e) {
        console.error(`Failed to get Properties Overall: ${e}`);
      } finally {
        setIsPeriodLoading(false);
        setIsCalculationMethodLoading(false);
      }

      setIsCalculationMethodLoading(true);
      try {
        const sortedProperties = sortById(
          await getProperties(selectedPeriod, signal)
        ) as PropertiesDTO;
        dispatch(setProperties(sortedProperties));
      } catch (e) {
        console.error(`Failed to get Properties: ${e}`);
      } finally {
        setIsPeriodLoading(false);
        setIsCalculationMethodLoading(false);
        fetchingState.current.overall = false;
        fetchingState.current.properties = false;
        // setIsFetchingOverall(false);
        // setIsFetchingProperties(false);
      }
      // }
    };

    updateData();

    // return () => {
    //   isMounted = false;
    // };
  }, [pageUpdated]);

  const onCalculationMethodChange = useCallback(() => {
    setPageUpdated(true);
  }, []);

  const OverallMetric = useCallback(
    ({
      title,
      subtitle,
      value,
      prefix,
      suffix,
      trend,
      other,
      className,
      reverseRiskColor,
    }: OverallMetricProps) => (
      <div className={`relative w-[30%] my-4 ${className ?? ""}`}>
        {title && <Subtitle>{title}</Subtitle>}
        {subtitle && (
          <SmallText className="text-secondary-gray-350 !text-[11px] !mt-0 !mb-0 !pb-0">
            {subtitle}
          </SmallText>
        )}
        <div className="flex gap-2">
          {other ? (
            other
          ) : (
            <>
              {value != null ? (
                <LargeText className="font-normal">
                  {displayValue(value, prefix ?? "", suffix ?? "%")}
                </LargeText>
              ) : (
                "N/A"
              )}
              {typeof trend === "number" ? (
                <TrendMetric
                  reverseRiskColor={reverseRiskColor}
                  trend={trend}
                />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    ),
    []
  );

  const PropertiesOverallSection = useCallback(() => {
    if (!propertiesOverall || shouldShowLoaderByChanges) {
      return (
        <div className="h-[182px] pt-4">
          <div
            style={{
              backgroundImage: `url(${PortfolioShimmerBg})`,
            }}
            className="h-full w-ful rounded-md animate-pulse bg-no-repeat bg-contain"
          />
        </div>
      );
    }

    const delinquencyPrc = calculateDelinquencyRate(
      propertiesOverall?.delinquency?.actual,
      propertiesOverall?.delinquency?.grossPotentialRent
    );

    const delinquencyTrend = calculateDelinquencyTrend(
      propertiesOverall?.delinquency?.actual,
      propertiesOverall?.delinquency?.grossPotentialRent,
      propertiesOverall?.delinquency?.previousActual,
      propertiesOverall?.delinquency?.previousGrossPotentialRent
    );

    return (
      <>
        <div className="inline-flex gap-6 w-full border-separate-[1px] border-secondary-gray-100">
          <OverallMetric
            title="NOI"
            value={propertiesOverall?.noi?.value}
            prefix="$"
            suffix=""
            trend={propertiesOverall?.noi?.trend}
            className="border-r-[1px] border-secondary-gray-100"
          />
          <OverallMetric
            title="Occupancy"
            value={propertiesOverall?.occupancy?.value}
            trend={propertiesOverall?.occupancy?.trend}
            className="border-r-[1px] border-secondary-gray-100"
          />
          <OverallMetric
            title={`Delinquency${
              selectedGranularity === "month" ? " (0-30 Days)" : ""
            }`}
            value={delinquencyPrc}
            prefix={
              propertiesOverall?.delinquency?.previousGrossPotentialRent === -1
                ? "$"
                : ""
            }
            suffix={
              propertiesOverall?.delinquency?.previousGrossPotentialRent === -1
                ? ""
                : "%"
            }
            trend={delinquencyTrend}
            reverseRiskColor={true}
          />
        </div>
        <div className="inline-flex gap-6 w-full">
          <OverallMetric
            title="Expected Renovations"
            value={propertiesOverall?.expectedRenovations?.count}
            suffix=""
            other={
              <div className="flex items-center gap-1 text-primary-dark text-xs">
                <LargeText className="font-normal text-secondary-gray-600">
                  {displayValue(
                    propertiesOverall?.expectedRenovations?.count,
                    "",
                    ""
                  )}
                </LargeText>
                <Bubble className=" bg-primary-light">$</Bubble>
                {propertiesOverall?.expectedRenovations?.totalCost}
              </div>
            }
            className="border-r-[1px] border-secondary-gray-100"
          />
          <OverallMetric
            title="Turnovers "
            value={propertiesOverall?.turnovers}
            suffix=""
            className="border-r-[1px]  border-secondary-gray-100"
          />
          <OverallMetric
            title="Expenses"
            other={
              <div className="relative flex justify-between w-full">
                <div>
                  <Subtext>Actual</Subtext>
                  <LargeText className="text-green-400">
                    {displayValue(propertiesOverall?.budget?.actual, "$")}
                  </LargeText>
                </div>
                <div>
                  <Subtext>Budget</Subtext>
                  <LargeText className="text-secondary-gray-400">
                    {displayValue(propertiesOverall?.budget?.planned, "$")}
                  </LargeText>
                </div>
                <div
                  className={`absolute bottom-0 w-full border-b-[3px] border-green-50`}
                ></div>
                <div
                  style={{
                    width: `${calculatePercentage(
                      propertiesOverall?.budget?.actual,
                      propertiesOverall?.budget?.planned
                    )}%`,
                  }}
                  className={`absolute bottom-0 border-b-[3px] border-green-400`}
                ></div>
              </div>
            }
          />
        </div>
      </>
    );
  }, [propertiesOverall, shouldShowLoaderByChanges]);

  const PropertiesSection = useCallback(() => {
    return (
      <>
        <div className="flex flex-col w-full">
          <div className="flex flex-col gap-4 w-full overflow-x-auto pb-20">
            {properties && !shouldShowLoaderByChanges ? (
              properties?.length === 0 ? (
                "No data found for the selected period and calculation metric. "
              ) : (
                properties?.reduce((result: JSX.Element[], property) => {
                  if (
                    !propertiesFilter?.length ||
                    propertiesFilter.find(
                      (filter) =>
                        filter.toLowerCase() === property.name.toLowerCase()
                    )
                  ) {
                    result.push(
                      <Property
                        key={property.id}
                        id={property.id.toString()}
                        name={property.name}
                        unitsCount={property.unitsCount}
                        occupancy={property.occupancy}
                        delinquency={property.delinquency}
                        budget={property.budget}
                        maintenanceExpenses={property.maintenanceExpenses}
                        utilitiesExpenses={property.utilitiesExpenses}
                        renewals={property.renewals}
                        collection={property.collection}
                        renovations={property.renovations}
                        // leaseRatio={property.leaseRatio}
                        expiredLeases={property.expiredLeases}
                        lossToLease={property.lossToLease}
                        noi={property.noi}
                        date={property.date}
                        lastUpdatedAt={property.lastUpdatedAt}
                        granularity={selectedGranularity}
                      />
                    );
                  }
                  return result;
                }, [])
              )
            ) : (
              <div className="h-[1500px] w-full">
                <div
                  style={{
                    backgroundImage: `url(${PropertiesShimmerBg})`,
                  }}
                  className="h-full w-ful rounded-md animate-pulse bg-no-repeat bg-contain"
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }, [properties, shouldShowLoaderByChanges, propertiesFilter]);

  const PropertiesFilterList = useCallback(
    ({
      options,
      applyFilter,
    }: {
      options: string[];
      applyFilter: (options: string[]) => void;
    }) => {
      return (
        <FilterDropdown
          options={options}
          applyFilter={applyFilter}
          searchLabel={"property"}
          position="right-0"
        />
      );
    },
    [properties?.length]
  );

  const updateProperties = useCallback(async (period?: string) => {
    try {
      setSelectedPeriod(period);
      setIsPeriodLoading(true);
      const p = await getPropertiesOverall(period);
      dispatch(setPropertiesOverall({ ...p }));
    } catch (e) {
      console.error(`Faild to get Properties Overall: ${e}`);
    }

    try {
      const sortedProperties = sortById(
        await getProperties(period)
      ) as PropertiesDTO;
      dispatch(setProperties(sortedProperties));
    } catch (e) {
      console.error(`Faild to get Properties: ${e}`);
    } finally {
      setIsPeriodLoading(false);
      setIsCalculationMethodLoading(false);
    }
  }, []);

  return (
    <div className="relative z-0 w-[58%] min-w-[745px] h-screen overflow-hidden">
      <div className="w-full px-6 pt-6 border-b-[1px] border-secondary-gray-350">
        <div className="flex items-center gap-3">
          <Title className="!m-0 whitespace-nowrap">Portfolio Metrics</Title>
          <InfoIcon onClick={() => setShowPortfolioInfoPopup(true)} />
          {showPortfolioInfoPopup && (
            <InfoPopup
              dismissPopup={() => setShowPortfolioInfoPopup(false)}
              title={"Portfolio Metrics"}
              text={`Here you can view your portfolio’s total metrics.
              the metrics.
              You can view the time update indication on the header of this section.`}
            />
          )}
          <FeedbackBox sectionName="portfolio" />
          {propertiesOverall?.lastUpdatedAt && (
            <div className="flex gap-2 items-center pl-6 text-secondary-gray-400">
              <UpdatedAtIcon />
            </div>
          )}
          <div className="w-full flex justify-end">
            <AppContext.Consumer>
              {({ user }) => (
                <div
                  className={`w-fit absolute top-2 right-1 flex gap-2 p-1 ${
                    isPeriodLoading || isCalculationMethodLoading
                      ? "opacity-60 pointer-events-none"
                      : ""
                  }`}
                >
                  {user?.calc_method ? (
                    <CustomizeCalculationMethod
                      initialMetricCalculationMethods={
                        user?.config.metricCalculationMethods
                      }
                      setIsLoading={setIsCalculationMethodLoading}
                      onChange={onCalculationMethodChange}
                    />
                  ) : (
                    ""
                  )}
                  <CustomizePeriod
                    initialGranularity={user?.config?.granularity || "month"}
                    setIsLoading={setIsPeriodLoading}
                    onPeriodChange={updateProperties}
                    onPeriodGranularityChange={setSelectedGranularity}
                    isActiveFeature={user?.calc_method}
                  />
                </div>
              )}
            </AppContext.Consumer>
          </div>
        </div>
        <PropertiesOverallSection />
      </div>
      <div style={{ height: "calc( 100% - 330px)" }} className="w-full py-6">
        <div className="relative flex items-center justify-between gap-3 px-6 w-full bg-white z-2">
          <div className="flex gap-2 items-center w-full mr-10">
            <Title className="!m-0">Properties</Title>
            <InfoIcon onClick={() => setShowPropertiesInfoPopup(true)} />
            {showPropertiesInfoPopup && (
              <InfoPopup
                dismissPopup={() => setShowPropertiesInfoPopup(false)}
                title={"Properties"}
                text={`You can view the details and performance metrics of your portfolio here. 
              The information displayed is up-to-date and reflects the high-level metrics above.
              You can filter the properties view from the top right corner of this section.`}
              />
            )}
            <FeedbackBox sectionName="properties" />
            {properties?.length ? (
              <div className="w-full flex justify-end">
                <PropertiesFilterList
                  options={[...new Set(properties.map((i) => i.name))]}
                  applyFilter={(selectedOptions) => {
                    setPropertiesFilter(selectedOptions);
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mt-6 px-6 overflow-auto h-full z-0">
          <PropertiesSection />
        </div>
        <div className="w-[60%] h-20 fixed bottom-0 right-0 bg-gradient-to-t from-white pointer-events-none"></div>
      </div>
    </div>
  );
}

export default Properties;
