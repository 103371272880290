import { useCallback, useState } from "react";
import { updateUserConfig } from "../api/api";
import { useAppDispatch } from "../app/hooks";
import {
  PortfolioBreakDownOptions,
  PortfolioGranularityType,
} from "../models/models";
import { AppDropdown } from "./Inputs";
import { OutlineIconButton } from "./Buttons";
import { capitalizeFirst } from "../utils/helpers";
import { ArrowDown } from "./Icons";
import { setUserConfig } from "../features/user/user-slice";

const getPastQuarters = (): string[] => {
  const now = new Date();
  const quarters = [];
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1; // JavaScript months are 0-based
  const currentQuarter = Math.ceil(currentMonth / 3); // Determine current quarter

  for (let year = currentYear - 2; year <= currentYear; year++) {
    for (let q = 1; q <= 4; q++) {
      if (year === currentYear && q > currentQuarter) {
        break; // Skip future quarters
      }
      quarters.unshift(`Q${q} ${year}`);
    }
  }
  return quarters;
};

export const CustomizeGranularity = ({
  setIsLoading,
  granularity = "month",
  onGranularityChange,
}: {
  setIsLoading?: (isLoading: boolean) => void;
  granularity: PortfolioGranularityType;
  onGranularityChange: (granularity: PortfolioGranularityType) => void;
}) => {
  return granularity ? (
    <AppDropdown
      className="!w-32"
      initialSelectedValue={granularity}
      multiSelect={false}
      closeOnSelect={true}
      options={Object.keys(PortfolioBreakDownOptions)}
      DropdownButton={() => (
        <OutlineIconButton className="!h-10 !w-28 !max-w-none border-secondary-gray-350 rounded-md text-secondary-gray-500 !p-[20px] mb-[20px]">
          {granularity ? capitalizeFirst(granularity) : "Month"}
          <ArrowDown />
        </OutlineIconButton>
      )}
      onSelectCallback={function (newGranularity: string[]): void {
        onGranularityChange(
          newGranularity[0]
            ? (newGranularity[0] as PortfolioGranularityType)
            : "month"
        );
      }}
    />
  ) : (
    ""
  );
};

export const CustomizePeriod = ({
  initialGranularity,
  setIsLoading,
  onPeriodChange,
  onPeriodGranularityChange,
  isActiveFeature,
}: {
  initialGranularity?: PortfolioGranularityType;
  setIsLoading: (isLoading: boolean) => void;
  onPeriodChange: (period?: string) => void;
  onPeriodGranularityChange: React.Dispatch<
    React.SetStateAction<"month" | "quarter" | "year" | undefined>
  >;
  isActiveFeature?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [granularity, setGranularity] = useState<PortfolioGranularityType>(
    isActiveFeature ? initialGranularity || "month" : "month"
  );
  let date = new Date();
  date.setDate(1); //the first day of current month

  const getPeriods = useCallback(
    (periodGranularity: PortfolioGranularityType): string[] => {
      switch (periodGranularity) {
        case "month":
          let d = new Date();
          d.setDate(1);
          let p = [
            d.toLocaleString("en-US", { month: "long", year: "numeric" }),
          ];
          for (let i = 0; i < 18; i++) {
            //1.5 years history
            d.setMonth(d.getMonth() - 1);
            p.push(
              d.toLocaleString("en-US", { month: "long", year: "numeric" })
            );
          }
          return p;
        case "quarter":
          return getPastQuarters();
        case "year":
          return Array.from(
            { length: 16 },
            (_, i) => " " + (new Date().getFullYear() - i).toString()
          );
      }
    },
    []
  );

  const [period, setPeriod] = useState<string>();
  const [periods, setPeriods] = useState<string[]>(getPeriods(granularity));

  const handlePeriodChange = async (newPeriod?: string) => {
    if (period === newPeriod || !newPeriod) return;
    setIsLoading && setIsLoading(true);

    let periodDate = "";
    if (granularity === "quarter") {
      periodDate = new Date(
        Date.UTC(
          parseInt(newPeriod.split(" ")[1]),
          (parseInt(newPeriod[1]) - 1) * 3,
          1
        )
      ).toISOString();
    } else if (granularity === "year") {
      periodDate = new Date(Date.UTC(Number(newPeriod), 0, 1)).toISOString();
    } else {
      periodDate = new Date(
        Date.UTC(
          parseInt(newPeriod.split(" ")[1]),
          new Date(`${newPeriod} 01`).getMonth()
        )
      ).toISOString();
    }
    setPeriod(newPeriod);
    onPeriodChange(periodDate);
  };

  const onGranularityChange = async (
    newGranularity?: PortfolioGranularityType
  ) => {
    onPeriodGranularityChange(newGranularity);
    if (newGranularity === granularity || !newGranularity) return;
    setIsLoading && setIsLoading(true);
    newGranularity && setGranularity(newGranularity);

    await updateUserConfig({
      granularity: newGranularity,
    });
    dispatch(
      setUserConfig({
        granularity: newGranularity,
      })
    );
    const newPeriods = getPeriods(newGranularity);
    setPeriods(newPeriods);
    setPeriod(newPeriods[0]);
    onPeriodChange();
  };

  return (
    <div className="flex gap-2">
      {isActiveFeature ? (
        <CustomizeGranularity
          setIsLoading={setIsLoading}
          granularity={granularity || "month"}
          onGranularityChange={onGranularityChange}
        />
      ) : (
        ""
      )}
      {periods?.length && (
        <AppDropdown
          initialSelectedValue={periods[0].toString()}
          multiSelect={false}
          closeOnSelect={true}
          options={periods.map((o) => o.toString())}
          DropdownButton={() => (
            <OutlineIconButton className="!h-10 !min-w-[160px] !w-[160px] !max-w-[160px] border-secondary-gray-350 rounded-md text-secondary-gray-500 !p-[20px] mb-[20px]">
              {period ? period : `Choose ${granularity}`}
              <ArrowDown />
            </OutlineIconButton>
          )}
          className="!pl-0 !ml-[-15px]"
          onSelectCallback={(o) =>
            handlePeriodChange(o[0] as PortfolioGranularityType)
          }
        />
      )}
    </div>
  );
};
