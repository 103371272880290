import { REPORT_TYPE, ContactGroups, AnyObjectWithId } from "../models/models";
import * as XLSX from 'xlsx';

export const capitalizeFirst = (sentence: string) => {
  if(!sentence?.length) return '';
    return sentence[0].toUpperCase() + sentence.slice(1);
}

export const capitalizeAll = (sentence: string) => {
  if(!sentence?.length) return '';
  return sentence.split(/ +/).map(word => (word[0]?.toUpperCase() ?? '') + word.slice(1) ?? '').join(' ');
}

export const sendEmail = (groupName: REPORT_TYPE, name: string, linkUrl: string, contactGroups?: ContactGroups, recepients?: string[]) => {
  let mailingList = '';
  if(contactGroups) {
    contactGroups[groupName].forEach(c => mailingList+= c.email+',');
  } else {
    mailingList = recepients?.join(',') ?? '';
  }

    const windowRef = window.open(`mailto:${mailingList.length ? mailingList : ""}?subject=Quarterly%20Report&body=Hi%20${name}!%0D%0AYour%20quarterly%20report%20attached%20in%20the%20link%20below:%0D%0A%0D%0A${linkUrl}`, '_blank');
    windowRef?.focus();
    setTimeout(function(){
       if(!windowRef?.document.hasFocus()) {
         windowRef?.close();
      }
    }, 500);
}

export const getTimeSince = (_pastTime: Date) => {
  const pastTime = typeof _pastTime === 'string' ? new Date(_pastTime) : _pastTime;
  var now = new Date();
  var diff = Math.round((now.getTime()-pastTime.getTime())/1000);
  var d = Math.floor(diff/(24*60*60)); /* though I hope she won't be working for consecutive days :) */
  diff = diff-(d*24*60*60);
  var h = Math.floor(diff/(60*60));
  diff = diff-(h*60*60);
  var m = Math.floor(diff/(60));
  
  return d ? d + " d" : h ? h + " h" : m ? m + " min" : "now";
 }

 export const displayValue = (value: number|null, prefix?: string, suffix?: string, toFixed?: number) => {
    if (value == null) return 'N/A';
    if(prefix) {
      return `${prefix}${parseFloat(value?.toFixed(toFixed !== undefined ? toFixed : 0)).toLocaleString()}${suffix??''}` ;
    } else {
    return `${parseFloat(value?.toFixed(toFixed !== undefined ? toFixed : 2)).toLocaleString()}${suffix??''}` ;
    }
 }

 export const sleep = async (milliseconds:number) => {
  await new Promise(resolve => {
      return setTimeout(resolve, milliseconds)
  });
};

export const calculatePercentage = (actual:number|null, planned:number|null) => {
  if(actual == null || !planned || actual > planned) return 100;
  return Math.min((actual / planned) * 100,100);
}

export const calculatePercentageOfChange = (previous:number|null, current:number|null) => {
  if(current == null || !previous) return 0;
  return ((current - previous) / previous) * 100
}

export const getYesterdayDate = () => {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  return new Date(d);
};

export const getMonthAgoDate = (date:Date) => {
  let d = date ? date : new Date();
  d.setMonth(d.getMonth() - 1);
  return new Date(d);
};

export const getNextMonthDate = (date:Date) => {
  let d = date ? date : new Date();
  d.setMonth(d.getMonth() + 1);
  return new Date(d);
};

export const getYearAgoDate = () => {
  let d = new Date();
  d.setFullYear(d.getFullYear() - 1);
  return new Date(d);
};

export const getDayAfter = (day: Date) => {
  let d = new Date(day);
  d.setDate(day.getDate() + 1);
  return new Date(d);
};

export const getDayBefore = (day: Date) => {
  let d = new Date(day);
  d.setDate(day.getDate() - 1);
  return new Date(d);
};

export const compare = ( a:AnyObjectWithId, b:AnyObjectWithId) => {
  if ( a.id < b.id ){
    return -1;
  }
  if ( a.id > b.id ){
    return 1;
  }
  return 0;
}

export const sortById = (array: AnyObjectWithId[]) => {
  if (!array?.length) return [];
  return [...array].sort(compare);
}

export const compareLabels = ( a: number, b:number) => {
  return b-a;
}

export const sortGraphData = (array: number[]) => {
  if (!array?.length) return [];
  const a =  [...array].sort(compareLabels);
  return a;
}

export const calculateDelinquencyRate = (actual:number|null, grossPotentialRent:number|null) => {
  if(grossPotentialRent === -1) return actual; //special case where actual stands for delinquancy value itself
  if(actual == null ||grossPotentialRent == null) return null;
  return (actual/grossPotentialRent)*100; //here actual stands for actual rent
}

export const calculateDelinquencyTrendFromActual = (actual:number|null, previousActual:number|null) => {
  return calculatePercentageOfChange(previousActual,actual);
}

export const calculateDelinquencyTrend = (actual:number|null, grossPotentialRent:number|null, previousActual:number|null, previousGrossPotentialRent:number|null) => {
  if(grossPotentialRent == null || previousGrossPotentialRent == null || grossPotentialRent === -1 || previousGrossPotentialRent === -1) {
    return calculateDelinquencyTrendFromActual(actual,previousActual);
  }
  if(actual == null || previousActual == null) { 
    return null
  }
    
  const delinquencyRate = calculateDelinquencyRate(actual, grossPotentialRent);
  const previousDelinquencyRate = calculateDelinquencyRate(previousActual, previousGrossPotentialRent);
  if(delinquencyRate == null || previousDelinquencyRate == null) return null;
  return delinquencyRate - previousDelinquencyRate;
}

export const convertCSVToExcel = (data: string[], name: string, date: string) => {
  const parsedData = data.map(row => {
    const regex = /(".*?"|[^",]+)(?=\s*,|\s*$)/g;
    return [...row.matchAll(regex)].map(match => match[0].replace(/\"/g, ''));
  });
  const worksheet = XLSX.utils.aoa_to_sheet(parsedData);
  const workbook = XLSX.utils.book_new();

  // Set the width of each column to 200px
  const colWidths = parsedData[0].map(() => ({ wpx: 80 }));
  worksheet['!cols'] = colWidths;

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const d = new Date(date);
  XLSX.writeFile(workbook, `${name}_${(d.getMonth()+1)}-${d.getDate()}-${d.getFullYear()}.xlsx`);
}

export const copyToClipboard = (text:string) => {
  navigator.clipboard.writeText(text);
}

export const getFormattedDateAndTime = (utcDate: Date) => {
  let date = new Date(utcDate);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let hour = date.getHours();
  let minutes = date.getMinutes().toLocaleString("en-US", { minimumIntegerDigits: 2 });
  return `${day}/${month} ${hour}:${minutes}`;
}

export const openFileInNewTab = (url: string) => {
  window.open(url, "_blank");
}