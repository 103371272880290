import { useCallback, useEffect, useState } from "react";
import { Radio } from "@material-tailwind/react";
import { getCalculationMethod, updateUserConfig } from "../api/api";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  CalculationMethod,
  CalculationMethodDTO,
  MetricCalculationMethodsDTO,
} from "../models/models";
import { Link, OutlineIconButton, PrimaryButton } from "./Buttons";
import { capitalizeFirst } from "../utils/helpers";
import { ArrowDown } from "./Icons";
import { setUserConfig } from "../features/user/user-slice";

enum Method {
  ACCRUAL = "accrual",
  CASH = "cash",
  HYBRID = "hybrid",
}

type FinancialMetric =
  | "NOI"
  | "Utilities"
  | "Expenses"
  | "Collection"
  | "Repairs and maintenance";

const BASE_METRICS_METHODS = {
  noi: Method.ACCRUAL,
  collection: Method.ACCRUAL,
  maintenanceExpenses: Method.ACCRUAL,
  expenses: Method.ACCRUAL,
  utilitiesExpenses: Method.ACCRUAL,
};

export const findCalculationMethod = (
  metricCalculationMethods: MetricCalculationMethodsDTO
) => {
  if (
    Object.values(metricCalculationMethods).every(
      (value) => value === Method.ACCRUAL
    )
  )
    return Method.ACCRUAL;
  if (
    Object.values(metricCalculationMethods).every(
      (value) => value === Method.CASH
    )
  )
    return Method.CASH;
  return Method.HYBRID;
};

export const CustomizeCalculationMethod = ({
  initialMetricCalculationMethods,
  onChange,
  setIsLoading,
}: {
  initialMetricCalculationMethods?: MetricCalculationMethodsDTO;
  onChange: any;
  setIsLoading: (isLoading: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  let userConfig = useAppSelector((state) => state.userData.user?.config);

  type NamedCalcMethods = {
    noi: { name: FinancialMetric; method: CalculationMethod };
    maintenanceExpenses: { name: FinancialMetric; method: CalculationMethod };
    expenses: { name: FinancialMetric; method: CalculationMethod };
    collection: { name: FinancialMetric; method: CalculationMethod };
    utilitiesExpenses: { name: FinancialMetric; method: CalculationMethod };
  };

  const CALC_METHODS: NamedCalcMethods = {
    noi: {
      name: "NOI",
      method: userConfig?.metricCalculationMethods?.noi || Method.ACCRUAL,
    },
    utilitiesExpenses: {
      name: "Utilities",
      method:
        userConfig?.metricCalculationMethods?.utilitiesExpenses ||
        Method.ACCRUAL,
    },
    expenses: {
      name: "Expenses",
      method: userConfig?.metricCalculationMethods?.expenses || Method.ACCRUAL,
    },
    collection: {
      name: "Collection",
      method:
        userConfig?.metricCalculationMethods?.collection || Method.ACCRUAL,
    },
    maintenanceExpenses: {
      name: "Repairs and maintenance",
      method:
        userConfig?.metricCalculationMethods?.maintenanceExpenses ||
        Method.ACCRUAL,
    },
  };

  const [calculationMethod, setCalculationMethod] = useState<CalculationMethod>(
    initialMetricCalculationMethods
      ? findCalculationMethod(initialMetricCalculationMethods)
      : Method.ACCRUAL
  );
  const [showMenu, setShowMenu] = useState(false);
  const [metricsCalcMethods, setMetricsCalcMethods] =
    useState<NamedCalcMethods>(CALC_METHODS);
  const [metricsCalcMethodsReset, setMetricsCalcMethodsReset] =
    useState<NamedCalcMethods>(CALC_METHODS);
  const [tempMetricsCalcMethods, setTempMetricsCalcMethods] =
    useState<NamedCalcMethods>(CALC_METHODS);

  const updateMethods = (
    calcMethods: NamedCalcMethods | CalculationMethodDTO,
    data: CalculationMethodDTO
  ) => {
    return Object.fromEntries(
      Object.entries(calcMethods).map(([metric, value]) => [
        metric,
        {
          ...value,
          method: data.metricCalculationMethods[metric],
        },
      ])
    ) as NamedCalcMethods;
  };

  useEffect(() => {
    const getData = async () => {
      const data = userConfig?.metricCalculationMethods;
      try {
        const calculationMethod =
          userConfig && data ? findCalculationMethod(data) : Method.ACCRUAL;

        setCalculationMethod(calculationMethod);
        setMetricsCalcMethods((prev) =>
          updateMethods(prev, {
            method: calculationMethod,
            metricCalculationMethods: data,
          })
        );
        setMetricsCalcMethodsReset((prev) =>
          updateMethods(prev, {
            method: calculationMethod,
            metricCalculationMethods: data,
          })
        );
      } catch (e) {
        console.log(e);
      }
    };

    userConfig?.metricCalculationMethods && getData();
  }, []);

  useEffect(() => {
    setTempMetricsCalcMethods(metricsCalcMethods);
  }, [metricsCalcMethods]);

  const handleCalculationMethodChange = async (method: Method) => {
    setCalculationMethod(method);
    if (method === Method.HYBRID) return;
    setIsLoading && setIsLoading(true);
    await updateMetricCalculationMethod(method);
    onChange();
  };

  const getResetMetodObject = () => {
    return {
      method: calculationMethod,
      metricCalculationMethods: { ...BASE_METRICS_METHODS },
    };
  };

  const getCalculationMetodObject = (method: Method) => {
    let calculationMetodObject: CalculationMethodDTO = getResetMetodObject();
    for (const metric of Object.keys(metricsCalcMethods) as Array<
      keyof NamedCalcMethods
    >) {
      calculationMetodObject &&
        calculationMetodObject.metricCalculationMethods &&
        (calculationMetodObject.metricCalculationMethods[metric] =
          method === Method.HYBRID
            ? metricsCalcMethods[metric].method
            : method);
    }
    return calculationMetodObject;
  };

  const updateMetricCalculationMethod = async (method: Method) => {
    setShowMenu(false);
    setIsLoading && setIsLoading(true);
    const { metricCalculationMethods }: CalculationMethodDTO =
      getCalculationMetodObject(method);
    await updateUserConfig({
      metricCalculationMethods,
    });
    if (metricCalculationMethods) {
      const calculationMethod = findCalculationMethod(metricCalculationMethods);
      setTempMetricsCalcMethods((prev) => {
        const updatedMetrics = Object.keys(prev).reduce((acc, key) => {
          acc[key as keyof NamedCalcMethods] = {
            ...prev[key as keyof NamedCalcMethods],
            method: calculationMethod,
          };
          return acc;
        }, {} as typeof prev);
        setMetricsCalcMethods(updatedMetrics);
        return updatedMetrics;
      });

      dispatch(
        setUserConfig({
          metricCalculationMethods,
          calculationMethod,
        })
      );
    }
  };

  useEffect(() => {
    setIsLoading && setIsLoading(true);
    onChange();
  }, [metricsCalcMethods]);

  const handleTempChange = useCallback((metric: string, method: Method) => {
    setTempMetricsCalcMethods((prev) => ({
      ...prev,
      [metric]: {
        name: tempMetricsCalcMethods[metric as keyof NamedCalcMethods].name,
        method,
      },
    }));
  }, []);

  const resetTempChange = () => {
    setTempMetricsCalcMethods(metricsCalcMethods);
  };

  const saveTempChange = async () => {
    setMetricsCalcMethods(tempMetricsCalcMethods);
    await updateUserConfig({
      metricCalculationMethods: Object.keys(tempMetricsCalcMethods).reduce(
        (acc, key) => {
          const metricKey = key;
          acc[metricKey as keyof MetricCalculationMethodsDTO] =
            tempMetricsCalcMethods[metricKey as keyof NamedCalcMethods].method;
          return acc;
        },
        {} as MetricCalculationMethodsDTO
      ),
    });
    dispatch(
      setUserConfig({
        metricCalculationMethods: tempMetricsCalcMethods,
        calculationMethod: Method.HYBRID,
      })
    );
  };

  return (
    <>
      <OutlineIconButton
        className="!h-10 !w-36 !max-w-none border-secondary-gray-350 rounded-md text-secondary-gray-500 !p-[20px] mb-[20px]"
        onClick={() => setShowMenu((prev) => !prev)}
      >
        {calculationMethod
          ? capitalizeFirst(calculationMethod)
          : "Calculation Method"}
        <ArrowDown />
      </OutlineIconButton>
      {showMenu ? (
        <>
          <div
            className="fixed z-10 w-full h-full top-0 left-0 bg-secondary-gray-350/20"
            onClick={() => setShowMenu(false)}
          ></div>
          <div className="top-12 absolute overflow-hidden z-10 mt-2 w-fit px-4 h-fit whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="flex py-4 pr-4">
              {Object.entries(Method).map((method) => (
                <Radio
                  key={method[1]}
                  color="blue"
                  name="calculation type"
                  label={capitalizeFirst(method[1])}
                  crossOrigin={undefined}
                  onChange={() => handleCalculationMethodChange(method[1])}
                  value={method}
                  checked={calculationMethod === method[1]}
                />
              ))}
            </div>
            {calculationMethod === Method.HYBRID ? (
              <>
                <div className="border-t border-t-gray-400 px-2 py-4 max-h-[55vh] overflow-auto">
                  {Object.keys(tempMetricsCalcMethods).map((metric) => (
                    <div className="flex gap-4 items-center" key={metric}>
                      <div className="w-36">
                        {
                          tempMetricsCalcMethods[
                            metric as keyof NamedCalcMethods
                          ].name
                        }
                      </div>
                      <Radio
                        labelProps={{
                          className: `text-xs`,
                        }}
                        color="blue"
                        name={metric}
                        label="Accrual"
                        crossOrigin={undefined}
                        checked={
                          tempMetricsCalcMethods[
                            metric as keyof NamedCalcMethods
                          ].method === Method.ACCRUAL
                        }
                        onChange={() =>
                          handleTempChange(metric, Method.ACCRUAL)
                        }
                      />
                      <Radio
                        labelProps={{
                          className: `text-xs`,
                        }}
                        color="blue"
                        name={metric}
                        label="Cash"
                        crossOrigin={undefined}
                        checked={
                          tempMetricsCalcMethods[
                            metric as keyof NamedCalcMethods
                          ].method === Method.CASH
                        }
                        onChange={() => handleTempChange(metric, Method.CASH)}
                      />
                    </div>
                  ))}
                </div>
                <div className="flex gap-4 justify-end py-6 px-4 bg-white items-center">
                  <Link
                    onClick={() => {
                      resetTempChange();
                    }}
                    className="pointer-events-auto"
                  >
                    Reset
                  </Link>
                  <PrimaryButton
                    onClick={async () => {
                      setShowMenu(false);
                      setIsLoading && setIsLoading(true);
                      await saveTempChange();

                      onChange();
                    }}
                    className="pointer-events-auto"
                  >
                    Update
                  </PrimaryButton>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
