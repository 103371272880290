import { Input, Checkbox, Radio } from "@material-tailwind/react";
import {
  ChangeEventHandler,
  FC,
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
  Ref,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, PrimaryButton } from "./Buttons";
import { SmallText } from "../styles/Typography";
import { capitalizeAll } from "../utils/helpers";
import { SearchIcon } from "./Icons";

type OptionsMap = {
  [o: string]: boolean;
};

type AppInputProps = {
  className?: string;
  ref?: Ref<any>;
  label?: string;
  value?: any;
  onChange?: (value: any) => void;
};

export const CheckboxDefault = ({
  checked,
  onClick,
  label,
  className,
  disabled,
}: {
  checked: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler;
  label: ReactNode;
  className?: string;
}) => (
  <Checkbox
    checked={checked}
    disabled={disabled}
    onChange={() => {}}
    onClick={onClick}
    label={label}
    crossOrigin={undefined}
    className={`rounded-sm text-primary checked:bg-primary checked:border-primary !min-w-[20px] ${
      className || ""
    }`}
  />
);

export const AppInput: FC<AppInputProps> = ({
  className,
  label,
  value,
  onChange,
}) => {
  return (
    <Input
      onChange={onChange}
      label={label}
      value={value}
      crossOrigin={undefined}
      className={`ml-[0.5px] !border rounded-sm !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 peer ${
        className ?? ""
      }`}
      labelProps={{
        className: `${
          !label
            ? "hidden"
            : "ml-3 px-1 !w-fit !h-3 peer-focus:!bg-white before:!hidden after:!hidden"
        }`,
      }}
    />
  );
};

type AppDropdownProps = {
  className?: string;
  options: string[];
  onSelectCallback: (properties: string[]) => void;
  label?: string;
  DropdownButton?: FunctionComponent;
  position?: string;
  maxWidth?: string;
  actions?: {
    primary?: { title: string; action: (selectedOptions: string[]) => void };
    secondary?: { title: string; action: () => void };
  };
  multiSelect?: boolean;
  showSearch?: boolean;
  defaultSelectAll?: boolean;
  showSelectAllBtn?: boolean;
  preSelect?: { [p: string]: boolean };
  buttonClassName?: string;
  searchLabel?: string;
  maxOptions?: number;
  closeOnSelect?: boolean;
  initialSelectedValue?: string;
  disabled?: boolean;
};

export const AppDropdown = ({
  searchLabel,
  label,
  DropdownButton,
  options,
  onSelectCallback,
  actions,
  position,
  className,
  multiSelect = true,
  showSearch,
  showSelectAllBtn = true,
  defaultSelectAll = true,
  maxWidth = "",
  maxOptions,
  closeOnSelect = false,
  initialSelectedValue,
  disabled = false,
}: AppDropdownProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [filterList, setFilterList] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(
    multiSelect && defaultSelectAll
  );
  const [optionsMap, setOptionsMap] = useState<OptionsMap | undefined>(
    typeof initialSelectedValue !== "undefined"
      ? { [initialSelectedValue]: true }
      : undefined
  );

  const getSelectedoptions = () => {
    return optionsMap
      ? Object.keys(optionsMap).filter((o) => optionsMap[o])
      : [];

    // return optionsMap
    // ? Object.keys(optionsMap).filter((o) => optionsMap[o] ?? (initialSelectedValue && initialSelectedValue === o))
    // : [];
  };

  const isAllSelected = () => {
    return !(optionsMap && Object.values(optionsMap).some((value) => !value));
  };

  useEffect(() => {
    setAllSelected((multiSelect && defaultSelectAll) || isAllSelected());
  }, [options]);

  useEffect(() => {
    setOptionsMap(
      options.reduce((obj, o) => {
        return {
          ...obj,
          [o]:
            multiSelect && defaultSelectAll ? true : initialSelectedValue === o,
        };
      }, {})
    );
  }, [options.length]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    } else {
      setFilterList("");
    }
  }, [isOpen]);

  useEffect(() => {
    onSelectCallback(getSelectedoptions());
    setAllSelected(isAllSelected());
  }, [optionsMap]);

  const onSelectAll = (selected: boolean) => {
    if (!optionsMap) return;
    setAllSelected(selected);
    setOptionsMap((prevState) =>
      Object.keys(prevState || optionsMap).reduce((obj, o) => {
        return {
          ...obj,
          [o]: selected,
        };
      }, {})
    );
  };

  const onSelect = (option: string, selected: boolean) => {
    if (!selected) setAllSelected(false);
    setOptionsMap((prevState) => {
      if (!prevState) return;
      const newState = { ...prevState };
      newState[option] = selected;
      setAllSelected(Object.values(newState).indexOf(false) === -1);
      return newState;
    });
    closeOnSelect && setIsOpen(false);
  };

  const onRadioSelect = (option: string, selected: boolean) => {
    if (!selected) setAllSelected(false);
    setOptionsMap((prevState) => {
      if (!prevState) return;
      const newState: { [label: string]: boolean } = Object.keys(
        prevState
      ).reduce((obj, o) => {
        return {
          ...obj,
          [o]: false,
        };
      }, {});
      newState[option] = selected;
      if (Object.values(newState).indexOf(false) === -1) {
        setAllSelected(true);
      }
      return newState;
    });
    closeOnSelect && setIsOpen(false);
  };

  return (
    <>
      <div
        className={`relative inline-block text-left ${
          disabled ? "opacity-50 pointer-events-none" : ""
        }`}
      >
        {isOpen && (
          <div
            className="fixed z-10 w-full h-full top-0 left-0 bg-secondary-gray-350/20"
            onClick={() => setIsOpen(false)}
          ></div>
        )}
        <div>
          {DropdownButton ? (
            <div
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onClick={() => setIsOpen((prevState) => !prevState)}
            >
              <DropdownButton />
            </div>
          ) : (
            <button
              type="button"
              className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              disabled={disabled}
              onClick={() => setIsOpen((prevState) => !prevState)}
            >
              {label}
              <svg
                className="-mr-1 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>
        {isOpen ? (
          <div
            style={{
              maxHeight:
                window.innerHeight - 410 < 250
                  ? "250px"
                  : "calc(" + window.innerHeight + "px - 410px)",
              minHeight: actions
                ? window.innerHeight - 600 < 250
                  ? "250px"
                  : multiSelect
                  ? "calc(" + window.innerHeight + "px - 600px)"
                  : "auto"
                : "auto",
            }}
            className={`${position ? position : "left-0"} ${
              window.innerHeight > 700 ? "my-6" : ""
            } ${
              maxWidth ? "max-w-[" + maxWidth + "]" : ""
            } top-10 absolute overflow-hidden z-10 mt-2 w-fit px-4 h-fit ${
              actions ? "pb-12" : ""
            } whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none `}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            {multiSelect || showSearch ? (
              <>
                <SearchIcon className="absolute top-[28px] left-[38px] !w-4" />
                <input
                  ref={inputRef}
                  className="mt-4 box-border w-11/12 rounded-full h-10 bg-secondary-gray-10 text-secondary-gray-400 pl-10 ml-2 !outline-none placeholder:text-secondary-gray-400"
                  onChange={(e) => setFilterList(e.target.value)}
                  placeholder={searchLabel || "Search..."}
                />
              </>
            ) : (
              ""
            )}
            <div
              style={{
                maxHeight: multiSelect
                  ? window.innerHeight - 615 < 220
                    ? "auto"
                    : "calc(" + window.innerHeight + "px - 615px)"
                  : "auto",
                minHeight: multiSelect
                  ? "calc(" + window.innerHeight + "px - 615px)"
                  : "auto",
              }}
              className={`max-h-[250px] left-0 pl-3 pr-3 pt-0 flex flex-col ${
                className || ""
              }`}
              role="none"
            >
              {multiSelect && maxOptions === undefined && showSelectAllBtn && (
                <div className="border-b border-b-secondary-gray-350 w-11/12 h-[52px] overflow-hidden">
                  <CheckboxDefault
                    checked={allSelected}
                    onClick={(e) => onSelectAll(e.currentTarget?.checked)}
                    label={
                      <SmallText className="font-normal grow-0">
                        Select all
                      </SmallText>
                    }
                  />
                </div>
              )}
              <div
                className={`flex flex-col overflow-auto ${
                  multiSelect ? "" : "my-4 px-1"
                }`}
                style={{
                  maxHeight: actions
                    ? multiSelect
                      ? window.innerHeight - 675 < 100
                        ? "100px"
                        : "calc(" + window.innerHeight + "px - 675px)"
                      : "auto"
                    : "auto",
                  paddingBottom: actions ? "50px" : "0",
                }}
              >
                {optionsMap
                  ? Object.keys(optionsMap)
                      .filter(
                        (o) =>
                          !filterList?.length ||
                          (filterList?.length &&
                            o.toLowerCase().indexOf(filterList.toLowerCase()) >
                              -1)
                      )
                      .map((option, index) => {
                        return multiSelect ? (
                          <CheckboxDefault
                            key={`${option}-menu-item-${index}`}
                            onClick={(e) =>
                              onSelect(option, e.currentTarget?.checked)
                            }
                            checked={optionsMap[option]}
                            disabled={
                              maxOptions !== undefined &&
                              !optionsMap[option] &&
                              getSelectedoptions().length >= maxOptions
                            }
                            label={
                              <SmallText className="font-light max-w-[190px] !text-[12px] whitespace-break-spaces">
                                {capitalizeAll(option)}
                              </SmallText>
                            }
                            className="text-gray-700 font-light text-sm"
                          />
                        ) : (
                          <Radio
                            className="h-4 w-4 p-1 m-0"
                            key={`${option}-menu-item-${index}`}
                            color="blue"
                            name="type"
                            label={
                              <SmallText className="text-[12px]">
                                {capitalizeAll(option)}
                              </SmallText>
                            }
                            crossOrigin={undefined}
                            checked={optionsMap[option]}
                            onChange={(e) =>
                              e.target.checked &&
                              onRadioSelect(option, e.currentTarget.checked)
                            }
                          />
                        );
                      })
                  : ""}
              </div>
            </div>
            {actions ? (
              <div className="absolute bottom-0 left-0 right-0 m-auto w-11/12 pointer-events-none">
                <div className="pt-20 mb-[-30px] bg-gradient-to-t from-white via-white to-transparent" />
                <div className="flex gap-4 justify-end pb-6 px-4 bg-white items-center">
                  <Link
                    onClick={() => {
                      actions.secondary?.action();
                      onSelectAll(false);
                      setIsOpen(false);
                    }}
                    className="pointer-events-auto"
                  >
                    {actions.secondary?.title}
                  </Link>
                  <PrimaryButton
                    onClick={() => {
                      actions.primary?.action(getSelectedoptions());
                      setIsOpen(false);
                    }}
                    className="pointer-events-auto"
                  >
                    {actions.primary?.title}
                  </PrimaryButton>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export const InputDate = ({
  setNewDate,
}: {
  setNewDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
}) => {
  const [date, setDate] = useState("");
  const [error, setError] = useState("");

  const handleDateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

    setDate(value);
    if (datePattern.test(value)) {
      setNewDate(new Date(value));
    }

    if (!datePattern.test(value)) {
      setError("Invalid date format. Please use mm/dd/yyyy.");
    } else {
      setError(""); // Clear error if the format is correct
    }
  };

  return (
    <div className="flex flex-col items-center mt-10">
      <h3 className="text-lg font-semibold mb-2">Enter Date (mm/dd/yyyy):</h3>
      <input
        type="text"
        value={date}
        onChange={handleDateChange}
        placeholder="mm/dd/yyyy"
        className="border-2 border-gray-300 rounded-md p-2 w-64 focus:outline-none focus:border-blue-500"
      />
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};
