import { Typography } from "@material-tailwind/react";
import { CSSProperties, MouseEventHandler, PropsWithChildren } from "react";

type TitleProps = {
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler;
  dataTestid?: string;
};

export const Title = ({
  children,
  className,
}: PropsWithChildren<TitleProps>) => (
  <Typography
    variant="h1"
    className={` text-secondary-gray-600 !font-normal !text-2xl font-custom !mb-0 ${
      className ?? ""
    }`}
  >
    {children}
  </Typography>
);

export const Subtitle = ({
  children,
  className,
}: PropsWithChildren<TitleProps>) => (
  <Typography
    variant="small"
    className={`text-secondary-gray-400 font-custom font-normal !mt-0 !mb-1 ${
      className || ""
    }`}
  >
    {children}
  </Typography>
);

export const ParagraphTitle = ({
  children,
  className,
  dataTestid,
}: PropsWithChildren<TitleProps>) => (
  <Typography
    variant="small"
    className={`${
      className ?? ""
    } text-secondary-gray-500 font-custom font-semibold !m-0 !max-w-[85%] `}
    data-testid={dataTestid}
  >
    {children || ""}
  </Typography>
);

export const LargeText = ({
  children,
  className,
  style,
  dataTestid,
}: PropsWithChildren<TitleProps>) => (
  <Typography
    variant="h6"
    className={` text-secondary-gray-600 !font-normal font-custom ${
      className ?? ""
    }`}
    style={style}
    data-testid={dataTestid}
  >
    {children}
  </Typography>
);

export const SmallText = ({
  children,
  className,
  style,
  onClick,
  dataTestid,
}: PropsWithChildren<TitleProps>) => (
  <Typography
    variant="small"
    className={`font-custom ${className ?? ""}`}
    style={style}
    onClick={onClick}
    data-testid={dataTestid}
  >
    {children}
  </Typography>
);

export const Paragraph = ({
  children,
  className,
}: PropsWithChildren<TitleProps>) => (
  <Typography
    variant="small"
    className={`${className ?? ""} font-custom text-secondary-gray-600 !m-0`}
  >
    {children || ""}
  </Typography>
);

export const LightText = ({
  children,
  className,
}: PropsWithChildren<TitleProps>) => (
  <Typography
    variant="small"
    className={`${className ?? ""} font-custom text-secondary-gray-400 !m-0`}
  >
    {children || ""}
  </Typography>
);

export const Subtext = ({
  children,
  className,
}: PropsWithChildren<TitleProps>) => (
  <Typography
    variant="small"
    className={`${
      className ?? ""
    } font-custom text-secondary-gray-400 text-xs !m-0`}
  >
    {children}
  </Typography>
);
